import React from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'

import PaywallPill, { PaywallPillProps } from '@/molecules/PaywallPill'

type Props = PaywallPillProps & {
  isPaywalled: boolean
  unlockedComponent?: ReactElement
}

const Paywalled = ({ isPaywalled, unlockedComponent, ...rest }: Props): JSX.Element => {
  if (isPaywalled) {
    return <PaywallPill {...rest} />
  }

  if (unlockedComponent) {
    return unlockedComponent
  }

  return <></>
}

export default Paywalled
