export const TAG_TARGET_TEMPLATES = 'TARGET_TEMPLATES'
export const TAG_TARGETS = 'TARGETS'
export const TARGETS_WITH_EMISSIONS = 'TARGETS_WITH_EMISSIONS'
export const TAG_TARGET_GRAPH = 'TARGET_GRAPH'
export const TAG_TARGETS_FROM_USER_SETTINGS = 'TAG_TARGETS_FROM_USER_SETTINGS'
export default [
  TAG_TARGETS,
  TARGETS_WITH_EMISSIONS,
  TAG_TARGET_TEMPLATES,
  TAG_TARGET_GRAPH,
  TAG_TARGETS_FROM_USER_SETTINGS,
]
