export const SLICE_NAME_ORGANIZATION = 'organization'
export const GET_ORGANIZATION = 'getOrganization'
export const GET_ORGANIZATION_USERS = 'getOrganizationUsers'
export const GET_ORGANIZATION_USERS_FILTERED_BY_BUSINESS_UNIT =
  'getOrganizationUsersFilteredByBusinessUnit'
export const GET_USERS_WITH_ACCESS_TO_BUSINESS_UNIT = 'getUsersWithAccessToBusinessUnit'
export const GET_ORGS_WITH_FACTOR_REQUESTS = 'getOrgsWithFactorRequests'
export const UPDATE_ORGANIZATION = 'updateOrganization'
export const CREATE_ORGANIZATION = 'createOrganization'
export const CREATE_CCF_BULK_IMPORT = 'createCcfBulkImport'
export const GET_CCF_BULK_IMPORT_TEMPLATE = 'getCcfBulkImportTemplate'
