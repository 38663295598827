import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { message } from 'antd/es'
import { RcFile } from 'antd/lib/upload'

import { Product, ProductLogEntry, ProductTypeWithExtraInfo } from '@cozero/models'
import { centralApiGatewayClient, logApiGatewayClient } from '@cozero/uris'

import {
  ProductConfigurationValues,
  SupplierProduct,
} from '@/pages/GenericLifecycleSteps/hooks/useProductOutletContext'

import { useAppDispatch } from '../redux'
import api from '../redux/api'
import { TAG_PRODUCT } from '../redux/products/constants'
import axios from '../utils/axios'

export interface UploadProductConfigurationParams {
  name: string
  fileObj?: RcFile
  signedUrl: string
  path: string
}

type UseProducts = {
  getProductTypes: () => Promise<void>
  getProduct: (productId: number) => Promise<Product>
  getSignPutProductConfigurationUrl: (filesNames: string[]) => Promise<
    {
      signedUrl: string
      path: string
    }[]
  >
  createProductConfiguration: (data: ProductConfigurationValues) => Promise<Product | undefined>
  updateProduct: (
    productId: string,
    data: Partial<ProductConfigurationValues>,
    showToast?: boolean,
  ) => Promise<Product | undefined>
  parseData: (product: Product) => ProductConfigurationValues
  getProductLogEntry: (productId: number, lifeCycleStepKey?: number) => Promise<ProductLogEntry[]>
  productTypes: ProductTypeWithExtraInfo[]
  product: Product | undefined
  loading: boolean
  deleteProduct: (productId: number) => Promise<Product | undefined>
  duplicateProduct: (productId: number) => Promise<Product | undefined>
  productSupplier: SupplierProduct | undefined
  setProductSupplier: Dispatch<SetStateAction<SupplierProduct | undefined>>
}

/**
 * Hook to fetch products from the API
 * @returns {UseProducts}
 */
const useProducts = (): UseProducts => {
  const { t, i18n } = useTranslation('common')

  const dispatch = useAppDispatch()
  const [productTypes, setProductTypes] = useState<ProductTypeWithExtraInfo[]>([])
  const [product, setProduct] = useState<Product>()
  const [productSupplier, setProductSupplier] = useState<SupplierProduct>()
  const [loading, setLoading] = useState(false)

  const getProductTypes = async (): Promise<void> => {
    const url = centralApiGatewayClient.products.PRODUCT_TYPES
    const { data } = await axios.get(url)
    setProductTypes(data)
  }

  const uploadProductConfigurationImage = async (
    logoFile: UploadProductConfigurationParams,
  ): Promise<{ path: string }> => {
    try {
      await fetch(logoFile.signedUrl, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': logoFile.fileObj?.type as string,
          'Content-Length': logoFile.fileObj?.size?.toString() as string,
        },
        body: logoFile.fileObj, // body data type must match "Content-Type" header
      })
      return { path: logoFile.path }
    } catch (error) {
      throw new Error('Error uploading image')
    }
  }

  const getSignPutProductConfigurationUrl = async (
    filesNames: string[],
  ): Promise<{ signedUrl: string; path: string }[]> => {
    const url = `${
      centralApiGatewayClient.products.SIGNED_PUT_PRODUCT_CONFIGURATION_URL
    }?fileNames=${encodeURIComponent(JSON.stringify(filesNames))}`
    const { data } = await axios.get(url)
    return data
  }

  const createProductConfiguration = async (
    data: ProductConfigurationValues,
  ): Promise<Product | undefined> => {
    try {
      const { photo } = data
      photo && (await uploadProductConfigurationImage(photo as UploadProductConfigurationParams))
      const result = await axios.post(centralApiGatewayClient.products.CREATE, {
        ...data,
        ...(photo ? { photo: (photo as UploadProductConfigurationParams).path } : {}),
      })
      setProduct(result.data)
      message.success(t('product.create-success'))
      return result.data
    } catch (error) {
      message.error(
        i18n.exists(`api-errors.${error.response.data.statusCode}`)
          ? t(`api-errors.${error.response.data.statusCode}`)
          : t('product.create-error'),
      )
      throw error
    }
  }

  const getProduct = async (productId: number): Promise<Product> => {
    const url = centralApiGatewayClient.products.GET_ONE.replace(':id', productId.toString())
    const { data } = await axios.get(url)
    setProduct(data)
    return data
  }
  const getProductLogEntry = async (
    productId: number,
    productlifecycleStepId?: number,
  ): Promise<ProductLogEntry[]> => {
    let url = logApiGatewayClient.productLogEntries.GET_MANY
    url = `${url}?productId=${productId}`

    if (productlifecycleStepId) {
      url = `${url}&productlifecycleStepId=${productlifecycleStepId}`
    }
    const { data } = await axios.get<ProductLogEntry[]>(url)
    return data
  }

  const updateProduct = async (
    productId: string,
    data: Partial<ProductConfigurationValues>,
    showToast = true,
  ): Promise<Product | undefined> => {
    try {
      const { photo } = data

      const payload = { ...data }

      if (photo && typeof photo !== 'string') {
        await uploadProductConfigurationImage(photo)
        payload.photo = photo.path
      } else if (photo === null) {
        payload.photo = null
      }

      const url = centralApiGatewayClient.products.UPDATE_ONE.replace(':id', productId)
      const res = await axios.put(url, payload)
      setProduct(res.data)
      dispatch(api.util.invalidateTags([{ type: TAG_PRODUCT, id: product?.id }]))
      if (showToast) {
        message.success(t('product.update-success'))
      }
      return res.data
    } catch (error) {
      message.error(
        i18n.exists(`api-errors.${error.response.data.statusCode}`)
          ? t(`api-errors.${error.response.data.statusCode}`)
          : t('product.update-error'),
      )
      throw error
    }
  }

  const deleteProduct = async (productId: number): Promise<Product | undefined> => {
    try {
      setLoading(true)
      const { data: deletedProduct } = await axios.delete<Product>(
        centralApiGatewayClient.products.DELETE_ONE.replace(':id', `${productId}`),
      )
      message.success(t('product.delete.successful'))
      return deletedProduct
    } catch (error) {
      setLoading(false)
      message.error(error.response.data.message || t('product.delete.error'))
    }
  }

  const duplicateProduct = async (productId: number): Promise<Product | undefined> => {
    try {
      setLoading(true)
      const { data: duplicatedProduct } = await axios.post<Product>(
        centralApiGatewayClient.products.DUPLICATE.replace(':id', `${productId}`),
      )
      message.success(t('product.duplicate.successful'))
      return duplicatedProduct
    } catch (error) {
      setLoading(false)
      message.error(t('product.duplicate.error'))
    }
  }

  const parseData = (data: Product): ProductConfigurationValues => {
    return {
      photo: data.image?.url ?? '',
      name: data.name,
      startDate: data.startDate as Date,
      endDate: data.endDate as Date,
      productType: data?.productType?.id as number,
      functionalUnit: data?.functionalUnit as number,
      mass: data?.mass as number,
      massUnit: (data?.massUnit?.id || data?.massUnitId) as number,
      tags: data.tags ?? [],
      responsibleId: data.responsibleId as number,
      territory: data?.territoryId as number,
      code: data.code || undefined,
      version: data.version || undefined,
    }
  }

  return {
    getProductTypes,
    getProduct,
    getSignPutProductConfigurationUrl,
    createProductConfiguration,
    updateProduct,
    parseData,
    getProductLogEntry,
    productTypes,
    product,
    duplicateProduct,
    deleteProduct,
    loading,
    productSupplier,
    setProductSupplier,
  }
}
export default useProducts
