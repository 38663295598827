/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import {
  RouteObject,
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
  useRoutes,
  useSearchParams,
} from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { TFunction } from 'i18next'

import { DEPRECATED_TO_NEW_ROUTES_MAPPING, routes } from '@cozero/utils'

import CALCULATION_ROUTES from '@/templates/Router/CALCULATION_ROUTES'

import NotFoundPage from '@/pages/404'
import Onboarding from '@/pages/Onboarding'

import LogProvider from '@/contexts/log'
import { usePageTitle } from '@/hooks/usePageTitle'
import { useAppSelector } from '@/redux'
import {
  getBelongsToLiveOrganisation,
  getHasCozeroEmail,
  getIsCozeroAdmin,
  selectUser,
} from '@/redux/auth'
import { config } from '@/utils/config'

import PageLayout from '../PageLayout'
import PrivateRoute from '../PrivateRoute'

import ACCOUNT_AND_BILLING_ROUTES from './ACCOUNT_AND_BILLING'
import ACT_ROUTES from './ACT_ROUTES'
import AUTH_ROUTES from './AUTH_ROUTES'
import CUSTOM_ACT_ROUTES from './CUSTOM_ACT_ROUTES'
import FACTOR_REQUEST_ROUTES from './FACTOR_REQUEST_ROUTES'
import HOME_ROUTES from './HOME_ROUTES'
import LOG_ROUTES from './LOG_ROUTES'
import ORGANIZATION_SETTINGS_ROUTES from './ORGANIZATION_SETTINGS_ROUTES'
import OVERVIEW_ROUTES from './OVERVIEW_ROUTES'
import PRODUCT_FLOW_ROUTES from './PRODUCT_FLOW_ROUTES'
import SETTINGS_ROUTES from './SETTINGS_ROUTES'
import SHARE_ROUTES from './SHARE_ROUTES'
import SUPPLIER_ENGAGEMENT_FLOW_ROUTES from './SUPPLIER_ENGAGEMENT_FLOW_ROUTES'

/**
 * This array contains the routes we have in our main application,
 * the structure is built from
 * Where should I put my route?
 * 1. Is it a  public route?
 * Put the route in the top level of the array after the home routes
 *
 * 2. Should the route url contain /<log,act,share,settings>/*?
 * Put the route in the correct namespace file
 *
 * 3. The route doesn't fit into any of these namespaces
 * Create a new namespace file in this
 * directory(src/Router/), and name it after the new namespace
 *
 * 4. The route shouldn't be in a specific namespace
 * 4.a Are you sure that it doesn't fit in a namespace?
 * ok, then place it at the same level as the <Home/>
 */
export const ROUTES = (t: TFunction): RouteObject[] => {
  return [
    {
      path: routes.base,
      children: [
        {
          path: routes.base,
          element: (
            <PrivateRoute>
              <PageLayout />
            </PrivateRoute>
          ),
          children: [
            {
              path: routes.onboarding,
              element: (
                <LogProvider>
                  <Onboarding />
                </LogProvider>
              ),
            },
            ...HOME_ROUTES(),
            ...LOG_ROUTES(t),
            ...OVERVIEW_ROUTES(t),
            ...ACT_ROUTES(t),
            ...SHARE_ROUTES(t),
            ...ORGANIZATION_SETTINGS_ROUTES(t),
            ...ACCOUNT_AND_BILLING_ROUTES(t),
            ...SETTINGS_ROUTES(t),
          ],
        },
        ...PRODUCT_FLOW_ROUTES(t),
        ...SUPPLIER_ENGAGEMENT_FLOW_ROUTES(t),
        ...FACTOR_REQUEST_ROUTES(t),
        ...CALCULATION_ROUTES(),
        ...AUTH_ROUTES(),
        ...CUSTOM_ACT_ROUTES(),
        {
          path: '*',
          element: (
            <PrivateRoute>
              <NotFoundPage />
            </PrivateRoute>
          ),
        },
      ],
    },
  ]
}

const isProd = ['production'].includes(config.NODE_ENV)

const Router = (): ReactElement => {
  const { t } = useTranslation('common')
  const Routes = useRoutes(ROUTES(t))
  const user = useAppSelector(selectUser)
  const { boot, trackEvent } = useIntercom()
  const isCozeroAdmin = useAppSelector(getIsCozeroAdmin)
  const hasCozeroEmail = useAppSelector(getHasCozeroEmail)
  const belongToLiveOrganisation = useAppSelector(getBelongsToLiveOrganisation)
  const debug = !isProd || isCozeroAdmin || hasCozeroEmail || !belongToLiveOrganisation
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  usePageTitle()

  useEffect(() => {
    if (user?.id && location.pathname === routes.base) {
      navigate(routes.dashboardHome.home)
    }
  }, [user?.id, location.pathname, navigate])

  useEffect(() => {
    if (user?.id) {
      boot({
        customLauncherSelector: '#intercom_launcher',
        email: user.email,
        userId: `${user.id}`,
        createdAt: `${user.createdAt}`,
        name: `${user.firstName} ${user.lastName}`,
        company: {
          companyId: `${user.organization?.id}` as string,
          createdAt: `${user.organization?.createdAt}`,
          name: user.organization?.name,
          monthlySpend: 49,
          plan: user.organization?.pricing?.name as string,
          size: user.organization?.totalEmployees as number,
          website: user.organization?.url as string,
          industry: user.organization?.industry?.name as string,
          customAttributes: {
            mode: (user.organization?.metadata as { mode?: string })?.mode,
            debug,
          },
        },
      })
      const metadata = {}
      trackEvent('initialized', metadata)
    }
  }, [boot, debug, trackEvent, user])

  React.useEffect(() => {
    Object.entries(DEPRECATED_TO_NEW_ROUTES_MAPPING).some(([oldRoute, newRoute]) => {
      const match = matchPath(oldRoute, location.pathname)

      if (match) {
        const prevSearchParams =
          Array.from(searchParams).length > 0 ? `?${searchParams.toString()}` : ''

        navigate(`${generatePath(newRoute, match.params)}${prevSearchParams}`, {
          replace: true,
        })

        return true
      }

      return false
    })
  }, [location, navigate, searchParams])

  return <>{Routes}</>
}
export default Router
