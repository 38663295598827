import React from 'react'
import { useTranslation } from 'react-i18next'

import { Collapse } from 'antd'

import classNames from 'classnames'

import { Collapsable as CollapsableAtom } from '../Collapsable/Collapsable'

import classes from './ListCard.module.less'

interface HeaderProps {
  children: React.ReactNode
  onClick?: () => void
}

interface TitleProps {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

interface ContentProps {
  children: React.ReactNode
}

interface CollapsableProps {
  children: React.ReactNode
  customHeaderLabel?: {
    open?: string
    close?: string
  }
  isExpanded?: boolean
  defaultExpanded?: boolean
  onChange?: (expanded: boolean) => void
}

interface ListCardProps {
  children: React.ReactNode
  selected?: boolean
  selectable?: boolean
  className?: string
  compact?: boolean
  onClick?: () => void
}

const Header: React.FC<HeaderProps> = React.memo(function Header({ children, onClick }) {
  return (
    <div className={classes.header} onClick={onClick}>
      {children}
    </div>
  )
})

const Title: React.FC<TitleProps> = React.memo(function Title({ children, className, style }) {
  return (
    <span className={classNames(classes.title, className)} style={style}>
      {children}
    </span>
  )
})

const Subtitle: React.FC<TitleProps> = React.memo(function Title({ children, className, style }) {
  return (
    <span className={classNames(classes.subtitle, className)} style={style}>
      {children}
    </span>
  )
})

const Content: React.FC<ContentProps> = React.memo(function Content({ children }) {
  return <div>{children}</div>
})

const Collapsable: React.FC<CollapsableProps> = React.memo(function Collapsable(props) {
  const { t } = useTranslation()
  const [internalExpanded, setInternalExpanded] = React.useState(
    props.isExpanded ?? props.defaultExpanded ?? false,
  )
  const isControlled = props.isExpanded !== undefined
  const activeKey = internalExpanded ? 'panel' : undefined

  const handleChange = (): void => {
    if (!isControlled) {
      setInternalExpanded(!internalExpanded)
    }

    if (props.onChange) {
      props.onChange(!internalExpanded)
    }
  }

  const header = (() => {
    if (props.customHeaderLabel?.open && !internalExpanded) {
      return props.customHeaderLabel.open
    } else if (props.customHeaderLabel?.close && internalExpanded) {
      return props.customHeaderLabel.close
    }

    return internalExpanded ? t('actions.show-less') : t('actions.show-more')
  })()

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <CollapsableAtom activeKey={activeKey} onChange={handleChange} reverseColumn center small>
        <Collapse.Panel header={header} key="panel">
          {props.children}
        </Collapse.Panel>
      </CollapsableAtom>
    </div>
  )
})

const ListCard: React.FC<ListCardProps> & {
  Header: React.FC<HeaderProps>
  Title: React.FC<TitleProps>
  Subtitle: React.FC<TitleProps>
  Content: React.FC<ContentProps>
  Collapsable: React.FC<CollapsableProps>
} = ({ compact = false, ...props }) => {
  return (
    <div
      className={classNames(classes.listCard, props.className, {
        [classes.selectable]: props.selectable,
        [classes.selected]: props.selected,
        [classes.compact]: compact,
      })}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  )
}

ListCard.Header = Header
ListCard.Title = Title
ListCard.Content = Content
ListCard.Collapsable = Collapsable
ListCard.Subtitle = Subtitle

export { ListCard }
