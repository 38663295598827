import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineGlobeAlt } from 'react-icons/hi'
import { useNavigate } from 'react-router'
import { useIntercom } from 'react-use-intercom'

import { routes } from '@cozero/utils'

import LifecycleModalTitle from '@/pages/GenericLifecycleSteps/LifecycleModalTitle'

import CustomHeader from '@/organisms/CustomModalHeader'
import Paywalled from '@/organisms/Paywalled'

import LinkCard from '@/molecules/LinkCard/LinkCard'
import PaywallModal from '@/molecules/PaywallModal'

import GhgImg from '@/assets/supplier-engagement/ghg.png'
import { usePricingFeature } from '@/hooks/usePricingFeature'

import classes from './classes.module.less'

const FactorRequestTypePicker = (): React.ReactElement => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { isFeatureEnabled } = usePricingFeature()
  const [openModal, setOpenModal] = useState(false)
  const { showNewMessages } = useIntercom()

  function onLinkClick(e: React.MouseEvent<HTMLElement, MouseEvent>): void {
    e.preventDefault()
    setOpenModal(true)
  }

  return (
    <div className={classes.container}>
      <div className={classes.sider}></div>
      <div className={classes.mainSection}>
        <div className={classes.headerSection}>
          <CustomHeader
            showArrow={true}
            title={t('suppliers.factor.request-picker-info.title')}
            onClose={() => navigate(-1)}
            goBack={() => navigate(-1)}
          />
        </div>
        <div className={classes.content}>
          <LifecycleModalTitle
            title={t('suppliers.factor.request-picker-info.title')}
            description={t('suppliers.factor.request-picker-info.description')}
          />
          <div className={classes.gridMain}>
            <LinkCard
              imageSrc={GhgImg}
              title={t('suppliers.factor.request-picker-info.cards.ghg.title')}
              description={t('suppliers.factor.request-picker-info.cards.ghg.description')}
              to={routes.log.factors.request.create.replace(':type', 'ghg')}
              btnLabel={t('suppliers.factor.request-picker-info.cards.btnLabel', {
                reportType: 'GHG',
              })}
            />
            <LinkCard
              icon={HiOutlineGlobeAlt}
              title={t('suppliers.factor.request-picker-info.cards.cbam.title')}
              description={t('suppliers.factor.request-picker-info.cards.cbam.description')}
              to={routes.log.factors.request.create.replace(':type', 'cbam')}
              btnLabel={t('suppliers.factor.request-picker-info.cards.btnLabel', {
                reportType: 'CBAM',
              })}
              onClick={!isFeatureEnabled('cbam') ? onLinkClick : undefined}
              badge={
                <div>
                  <Paywalled isPaywalled={!isFeatureEnabled('cbam')} />
                </div>
              }
            />
          </div>
        </div>
      </div>
      <PaywallModal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        title={'Premium features'}
        modalTitle={t('paywall.cbam.title')}
        description={t('paywall.cbam.description')}
        onClick={() => showNewMessages(t('paywall.intercom.message', { featureName: 'CBAM' }))}
      />
    </div>
  )
}

export default FactorRequestTypePicker
