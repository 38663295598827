export const GET_ACTIVE_PRODUCTS = 'getActiveProducts'
export const GET_PRODUCTS = 'getProducts'
export const GET_PRODUCT = 'getProduct'
export const CREATE_PRODUCT = 'createProduct'
export const CREATE_PCF_BULK_IMPORT = 'createPcfBulkImport'
export const REQUEST_PCF_TEMPLATE = 'requestPcfTemplate'
export const GET_PCF_BULK_IMPORTS = 'getPcfBulkImports'
export const GET_ONE_PCF_BULK_IMPORT = 'getOnePcfBulkImport'
export const GET_ONE_PCF_BULK_IMPORT_FILES_URLS = 'getOnePcfBulkImportFilesUrls'
export const UPDATE_ONE_PCF_BULK_IMPORT = 'updateOnePcfBulkImport'
export const DELETE_ONE_BULK_IMPORT = 'deleteOnePcfBulkImport'
export const GET_ORGANIZATION_TOTAL_PRODUCT_EMISSIONS = 'getOrganizationTotalProductEmissions'
export const EDIT_PRODUCT = 'editProduct'
export const GET_PRODUCT_COUNT = 'getProductCount'
export const GET_PCF_SIGNED_URL = 'getPcfSignedUrl'
export const UPLOAD_BI_TO_SIGNED_URL = 'uploadBIFileToSignedUrl'
export const GET_PRODUCT_DETAILS_BY_ID = 'getProductDetailsById'

export const TAG_ACTIVE_PRODUCTS = 'PRODUCTS'
export const TAG_PRODUCT = 'PRODUCT'
export const TAG_PCF_BULK_IMPORTS = 'PCF_BULK_IMPORTS'

export default [TAG_ACTIVE_PRODUCTS, TAG_PRODUCT, TAG_PCF_BULK_IMPORTS]
