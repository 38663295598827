import { useTranslation } from 'react-i18next'

import Paywalled from '@/organisms/Paywalled'

import Tag from '@/atoms/Tag'

interface IntegrationStatusTagProps {
  active: boolean
  isPremium?: boolean
}

const IntegrationStatusTag = ({ active, isPremium }: IntegrationStatusTagProps): JSX.Element => {
  const { t } = useTranslation('common')

  return (
    <Paywalled
      isPaywalled={Boolean(isPremium)}
      unlockedComponent={
        <Tag showDot type={active ? 'success' : 'default'} shape="pill">
          {active
            ? t('settings.integrations.fields.active.title')
            : t('settings.integrations.fields.active.not')}
        </Tag>
      }
    />
  )
}

export default IntegrationStatusTag
