/* eslint react-hooks/exhaustive-deps: 2 */

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineInformationCircle } from 'react-icons/hi2'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Col, Popover, Tabs } from 'antd/es'

import { routes } from '@cozero/utils'

import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useAppSelector } from '@/redux'
import { getIsAdmin, getIsManager, getIsRootBusinessUnitUser } from '@/redux/auth'
import { config } from '@/utils/config'

export const OrganizationSettings = (): ReactElement => {
  const { t } = useTranslation()
  const { TabPane } = Tabs
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isManager = useAppSelector(getIsManager)
  const isAdmin = useAppSelector(getIsAdmin)
  const { isFeatureEnabled } = usePricingFeature()
  const isRootBusinessUnitUser = useAppSelector(getIsRootBusinessUnitUser)

  const MENU_ITEMS = useMemo(() => {
    const items: { uri: string; title: string }[] = []

    items.push({
      uri: routes.organizationSettings.locations.base,
      title: t('layout.locations'),
    })

    items.push({
      uri: routes.organizationSettings.businessUnits.base,
      title: t('layout.business-units'),
    })

    if (isAdmin && isRootBusinessUnitUser) {
      items.push({
        uri: routes.organizationSettings.revenue,
        title: t('layout.revenue'),
      })
    }

    items.push({
      uri: routes.organizationSettings.users.base,
      title: t('settings.users.title'),
    })

    if ((isManager || isAdmin) && isFeatureEnabled('user-functions')) {
      items.push({
        uri: routes.organizationSettings.userFunctions,
        title: t('layout.user-functions'),
      })
    }

    if (isAdmin) {
      items.push({
        uri: routes.organizationSettings.organizationInfo,
        title: t('settings.organization.title'),
      })
    }

    return items
  }, [isAdmin, isFeatureEnabled, isManager, isRootBusinessUnitUser, t])

  const currentTab = useMemo(
    () => MENU_ITEMS.find((item) => pathname.startsWith(item.uri)) ?? MENU_ITEMS[0],
    [MENU_ITEMS, pathname],
  )

  if (routes.organizationSettings.base === pathname) {
    return <Navigate to={MENU_ITEMS[0].uri} replace />
  }

  return (
    <>
      <Tabs
        activeKey={currentTab.uri}
        defaultActiveKey={MENU_ITEMS[0].uri}
        onTabClick={(key) => navigate(key)}
        tabBarExtraContent={{
          right: config.COMMIT_HASH && (
            <Col span={1}>
              <Popover content={config.COMMIT_HASH}>
                <HiOutlineInformationCircle />
              </Popover>
            </Col>
          ),
        }}
      >
        {MENU_ITEMS.map(({ uri, title }) => (
          <TabPane key={uri} tab={title}>
            <Outlet />
          </TabPane>
        ))}
      </Tabs>
    </>
  )
}
