import React from 'react'
import { FaChevronDown } from 'react-icons/fa'

import { Collapse, CollapseProps } from 'antd'

import classNames from 'classnames'

import classes from './Collapsable.module.less'

type Props = Omit<CollapseProps, 'ghost' | 'expandIcon'> & {
  reverseColumn?: boolean
  reverseRow?: boolean
  center?: boolean
  small?: boolean
}

export const Collapsable: React.FC<Props> = React.memo(function CollapsablePanel({
  children,
  className,
  center,
  reverseColumn,
  reverseRow,
  small,
  ...props
}) {
  return (
    <Collapse
      className={classNames(className, classes.collapsable, {
        [classes.reverseColumn]: reverseColumn,
        [classes.reverseRow]: reverseRow,
        [classes.center]: center,
        [classes.small]: small,
      })}
      ghost={true}
      expandIcon={({ isActive }) => (
        <FaChevronDown
          style={{
            transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.2s ease',
          }}
        />
      )}
      {...props}
    >
      {children}
    </Collapse>
  )
})
