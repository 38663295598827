/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Popconfirm, Spin, Upload, UploadProps } from 'antd/es'
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface'
import { RcFile } from 'antd/lib/upload'

import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import { chunk } from 'lodash-es'

import { File } from '@cozero/models'

import Modal from '@/molecules/Modal'
import Table from '@/molecules/Table'

import Button from '@/atoms/Button'
import Form from '@/atoms/Form'
import { message } from '@/atoms/Messages/Messages'
import Text from '@/atoms/Text'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAppSelector } from '@/redux'
import { getIsUserReadOnly } from '@/redux/auth'
import {
  useDeleteFileFromLogMutation,
  useGetLogFilesQuery,
  useLazyGetLogFilesPutUrlQuery,
  useUploadFileToSignedUrlMutation,
  useUploadFilesToLogMutation,
} from '@/redux/files'

import classes from './classes.module.less'

const FILE_UPLOAD_BATCH_NUMBER = 5

type LogFile = {
  name: string
  fileObj?: RcFile
  signedUrl: string
  path: string
}
interface FilesProps {
  loadExistingFiles?: boolean
  logIds: number[]
  isVisible: boolean
  isReadOnly: boolean
  onClose: () => void
  onSuccess?: () => void
  closeAfterUpload?: boolean
  successMessage?: string
  errorMessage?: string
}

function FilesModal({
  logIds,
  isVisible,
  onClose,
  onSuccess,
  isReadOnly = false,
  loadExistingFiles = true,
  closeAfterUpload,
  successMessage,
  errorMessage,
}: FilesProps): JSX.Element {
  const { t } = useTranslation('common')
  const { data: files, isLoading: filesLoading } = useGetLogFilesQuery(
    { logId: logIds[0] },
    { skip: !loadExistingFiles || logIds.length === 0 },
  )
  const [getLogFilesPutUrl, { isLoading: logFilesPutUrlLoading }] = useLazyGetLogFilesPutUrlQuery()
  const [uploadFilesToLog, { isLoading: uploadFilesToLogLoading }] = useUploadFilesToLogMutation()
  const [deleteFileFromLog, { isLoading: deleteFileFromLogLoading }] =
    useDeleteFileFromLogMutation()
  const [uploadFileToSignedUrl] = useUploadFileToSignedUrlMutation()
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const [fileList, setFileList] = useState<UploadFile<unknown>[]>([])

  const userIsReadOnly = useAppSelector(getIsUserReadOnly) || isReadOnly

  const loading =
    logFilesPutUrlLoading || uploadFilesToLogLoading || deleteFileFromLogLoading || filesLoading

  const getSignedUrl = React.useCallback(
    async (newFileList: UploadFile<unknown>[]): Promise<LogFile[]> => {
      const urls = await getLogFilesPutUrl({ fileNames: newFileList.map(({ name }) => name) })

      return [
        ...newFileList.map((newFile, index) => ({
          name: newFile.name,
          fileObj: newFile.originFileObj,
          signedUrl: urls.data?.[index].signedUrl || '',
          path: urls.data?.[index].path || '',
        })),
      ]
    },
    [getLogFilesPutUrl],
  )

  const onUpload = React.useCallback(
    ({ fileList: newFileList }: UploadChangeParam<UploadFile<unknown>>): void => {
      setFileList(newFileList)
    },
    [],
  )

  // To circumvent default behavior of Upload from antd
  const dummyRequest = React.useCallback(
    ({ file, onSuccess }: Parameters<NonNullable<UploadProps['customRequest']>>[0]) => {
      setTimeout(() => {
        onSuccess && onSuccess(file)
      })
    },
    [],
  )

  const submitForm = React.useCallback(async (): Promise<void> => {
    if (fileList.length === 0) {
      return
    }

    setIsSubmitting(true)

    const formData = new FormData()

    for (const file of fileList) {
      if (file.originFileObj) {
        formData.append('files', file.originFileObj)
      }
    }

    try {
      const filesToUpload = await getSignedUrl(fileList)

      const filesPaths: string[] = []

      const promises = filesToUpload.map(async (file) => {
        await uploadFileToSignedUrl(file)

        return file.path
      })

      const promsChunks = chunk(promises, FILE_UPLOAD_BATCH_NUMBER)

      for (const batch of promsChunks) {
        const paths = await Promise.all(batch)

        filesPaths.push(...paths)
      }

      const result = await uploadFilesToLog({ logIds, files: filesPaths })

      if ('error' in result) {
        throw result.error?.message
      }

      message.success(successMessage || t('log.files.uploaded'))
      onSuccess?.()

      if (closeAfterUpload) {
        onClose()
      }
    } catch (e) {
      message.error(errorMessage || t('log.files.error'))
    } finally {
      setFileList([])
      setIsSubmitting(false)
    }
  }, [
    fileList,
    getSignedUrl,
    uploadFilesToLog,
    logIds,
    onSuccess,
    successMessage,
    t,
    closeAfterUpload,
    uploadFileToSignedUrl,
    onClose,
    errorMessage,
  ])

  const deleteFile = React.useCallback(
    async (file: File): Promise<void> => {
      if (!file.logId) {
        return
      }

      try {
        const result = await deleteFileFromLog({ fileId: file.id, logId: file.logId })

        if ('error' in result) {
          throw result.error?.message
        }

        message.success(t('log.files.deleted'))
      } catch (e) {
        message.error(t('log.files.error'))
      }
    },
    [deleteFileFromLog, t],
  )

  const columns = React.useMemo(
    () => [
      {
        title: t('log.files.name'),
        dataIndex: 'name',
        key: 'name',
        render(text: string, record: File) {
          return (
            <Button href={record.url || ''} type="link" target="_blank" rel="noreferrer">
              {record.name}
            </Button>
          )
        },
      },
      {
        title: '',
        key: 'actions',
        render(text: string, record: File) {
          if (userIsReadOnly) {
            return
          }

          return (
            <Popconfirm
              title={t('log.files.delete-warning')}
              onConfirm={() => deleteFile(record)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Button
                category={AnalyticsCategories.FILES}
                action="delete"
                color="danger"
                title={t('actions.delete')}
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )
        },
      },
    ],
    [t, userIsReadOnly, deleteFile],
  )

  return (
    <Modal
      title={t('log.files.modal.title')}
      open={isVisible}
      closable={false}
      onOk={!userIsReadOnly ? submitForm : undefined}
      onCancel={onClose}
      okText={t('log.files.upload')}
      cancelText={t('log.files.close-modal')}
      confirmLoading={isSubmitting}
    >
      <Spin spinning={isSubmitting || loading}>
        <Table columns={columns} dataSource={files || []} rowKey="id" />
      </Spin>
      {!userIsReadOnly && (
        <Form
          category={AnalyticsCategories.FILES}
          layout="vertical"
          onFinish={submitForm}
          className={classes.uploadForm}
        >
          <Form.Item name="upload">
            <Upload
              fileList={fileList}
              onChange={onUpload}
              name="file"
              customRequest={dummyRequest}
            >
              <Button
                category={AnalyticsCategories.FILES}
                action="select"
                prefixIcon={<UploadOutlined />}
              >
                {t('log.files.select')}
              </Button>
              <Text className={classes.fileInfo}>{t('log.files.info')}</Text>
            </Upload>
          </Form.Item>
        </Form>
      )}
    </Modal>
  )
}

export default FilesModal
