import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { HiOutlineBookOpen, HiOutlineMinusCircle, HiTrash } from 'react-icons/hi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { AutoComplete, Col, Modal, Select, Spin, Tabs, message } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { OptionProps } from 'antd/lib/select'

import { skipToken } from '@reduxjs/toolkit/query'
import { produce } from 'immer'

import { BusinessUnit, Geolocation, Metadata } from '@cozero/models'
import { routes } from '@cozero/utils'

import ConfirmationModal from '@/organisms/ConfirmationModal'

import { AdvancedOption } from '@/molecules/AdvancedOption'
import BusinessUnitsDropdown from '@/molecules/BusinessUnitsDropdown'
import OnboardingStepModal, { onboardingModalStyle } from '@/molecules/OnboardingStepModal'
import TagInput from '@/molecules/TagInput'

import Alert from '@/atoms/Alert'
import Button from '@/atoms/Button'
import Form from '@/atoms/Form'
import InputField from '@/atoms/InputField'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAdminContext } from '@/contexts/admin'
import { useAppContext } from '@/contexts/app'
import { useLogContext } from '@/contexts/log'
import { useSubscriptionContext } from '@/contexts/subscription'
import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useSelectedBusinessUnit } from '@/hooks/useSelectedBusinessUnit'
import { useAppDispatch, useAppSelector } from '@/redux'
import { getIsManagerOrAdmin, selectUserOrganization, useLazyGetUserDataQuery } from '@/redux/auth'
import { useGetActiveBusinessUnitsQuery } from '@/redux/businessUnits'
import {
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useDisableLocationMutation,
  useEditLocationMutation,
  useGetLocationQuery,
  useGetLocationsQuery,
  useLazyGetAddressQuery,
} from '@/redux/locations'
import { selectSteps, setSteps, useUpdateOnboardingMutation } from '@/redux/onboarding'
import { useGetOrganizationUsersQuery } from '@/redux/organizations'

import classes from './classes.module.less'

export type LocationDetails = {
  name: string
  businessUnitName: string
  responsibleUser?: string
  role?: string
  email?: string
}

const UpsertLocation = (): ReactElement => {
  const { id } = useParams()
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const urlLocation = useLocation()
  const [locationForm] = Form.useForm()
  const { selectedBusinessUnit } = useSelectedBusinessUnit()
  const { isFeatureEnabled } = usePricingFeature()
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const { loading: appLoading } = useAppContext()
  const { data: location } = useGetLocationQuery(id ?? skipToken)
  const [createLocation] = useCreateLocationMutation()
  const [updateLocation] = useEditLocationMutation()
  const [deleteLocation] = useDeleteLocationMutation()
  const [disableLocation] = useDisableLocationMutation()
  const [getMe] = useLazyGetUserDataQuery()
  const steps = useAppSelector(selectSteps)
  const [updateOnboarding] = useUpdateOnboardingMutation()
  const organization = useAppSelector(selectUserOrganization)
  const { data: businessUnits = [] } = useGetActiveBusinessUnitsQuery(
    { businessUnitScopeId: selectedBusinessUnit?.id ?? -1, populate: true },
    { skip: !selectedBusinessUnit },
  )
  const { data: locations } = useGetLocationsQuery(
    { selectedBusinessUnitId: selectedBusinessUnit?.id ?? -1 },
    { skip: !selectedBusinessUnit },
  )
  const { resetLog, error: logError } = useLogContext()
  const { reset, loading, error: adminError } = useAdminContext()
  const { setSubscribeModalSettings, getLimit } = useSubscriptionContext()
  const { data: users } = useGetOrganizationUsersQuery(
    { businessUnitId: selectedBusinessUnit?.id },
    { skip: !selectedBusinessUnit?.id },
  )
  const searchParams = new URLSearchParams(urlLocation.search)
  const businessUnitId = parseInt(searchParams.get('businessUnitId') || '')
  const [selectedLocationBusinessUnit, setSelectedLocationBusinessUnit] = useState<number>(
    location ? location.businessUnit?.id : businessUnitId,
  )
  const [addresses, setAddresses] = useState<Geolocation[]>([])
  const [hasFeedback, setHasFeedback] = useState<boolean>(false)
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>()
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [formValues, setFormValues] = useState<Store>()
  const [locationDetails, setLocationDetails] = useState<LocationDetails>()
  const [address, setAddress] = useState(location?.address || '')
  const [getAddressesAutocomplete] = useLazyGetAddressQuery()
  const dispatch = useAppDispatch()

  async function fetchAndSetOptions(searchText: string): Promise<void> {
    try {
      const results = await getAddressesAutocomplete(searchText).unwrap()
      if (results) {
        setAddresses(results)
      }
    } catch (err) {
      message.error(t('location.error'))
    }
  }

  useEffect(() => {
    if (adminError) {
      if (adminError.message === 'LOCATION_LIMIT_EXCEEDED') {
        const limit = getLimit(organization, 'locations')
        setSubscribeModalSettings({
          closable: true,
          title: t('subscription.upgrade-modal.title-limit', {
            limit: limit?.max,
            item: t('locations.title'),
          }),
          visible: true,
        })
      } else {
        message.error(adminError.message, 5)
      }
    }
    if (logError) {
      message.error(logError as string, 5)
    }
  }, [adminError, logError])

  async function searchAddress(searchText: string): Promise<void> {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    const timeout = setTimeout(async () => {
      await fetchAndSetOptions(searchText)
    }, 300)
    setTimeoutId(timeout)

    const option = addresses.find((option) => option.title === searchText)

    if (option) {
      setHasFeedback(true)
      if (
        option.resultType === 'houseNumber' ||
        option.resultType === 'street' ||
        option.resultType === 'locality' ||
        option.resultType === 'place' ||
        option.resultType === 'administrativeArea'
      ) {
        return Promise.resolve()
      }
      return Promise.reject(t('location.address-format'))
    }
  }

  function goOnePageBack(): void {
    goBack()
  }

  function goBack(number?: number): void {
    navigate(number || -1)
  }

  const handleUpdateSteps = useCallback(async (key: string) => {
    const stepIndex = steps?.findIndex((step) => step.onboardingStep.key === key)
    if (stepIndex === undefined && stepIndex !== -1) {
      throw new Error('Step not found')
    }
    const clonedSteps = steps ? [...steps] : []
    const updatedSteps = produce(clonedSteps, (draft) => {
      draft[stepIndex].completed = false
    })
    const data = await updateOnboarding({
      steps: updatedSteps,
    }).unwrap()
    if (data) {
      await getMe().unwrap()
      dispatch(setSteps(data))
      Modal.success({
        content: <OnboardingStepModal stepKey={key} />,
        ...onboardingModalStyle,
      })
    }
  }, [])

  async function onDeleteLocation(): Promise<void> {
    try {
      const isLastLocation = locations?.length === 1
      if (location) {
        if (isLastLocation) {
          await handleUpdateSteps('locations')
          await handleUpdateSteps('logs')
        }
        const res = await deleteLocation(location.id).unwrap()
        if (res) {
          navigate(-1)
          return message.success(t('location.delete.successful'))
        }
      }
    } catch (error) {
      message.error(t('location.delete.error'))
    }
  }

  async function onDisableLocation(): Promise<void> {
    try {
      const isLastLocation = locations?.length === 1
      if (location) {
        if (isLastLocation) {
          await handleUpdateSteps('locations')
          await handleUpdateSteps('logs')
        }
        const res = await disableLocation(location.id).unwrap()
        if (res) {
          navigate(-1)
          return message.success(t('location.disable.successful'))
        }
      }
    } catch (error) {
      message.error(t('location.disable.error'))
    }
  }

  async function handleUpsertLocation(values: Store): Promise<void> {
    setIsFormLoading(true)
    try {
      const tags = values.tags
      if (tags) {
        delete values.tags
      }
      if ((values.address && !address) || address !== values.address) {
        message.error(t('location.errors.address-missing'))
        return
      }
      if (values.address) {
        delete values.address
      }

      if (values?.id) {
        await updateLocation({
          id: values?.id,
          location: {
            ...values,
            metadata: {
              ...(location?.metadata as Metadata),
              tags,
            },
            ...(values.responsible?.id
              ? { responsible: values.responsible }
              : { responsible: null }),
            businessUnit: {
              id: selectedLocationBusinessUnit,
            } as BusinessUnit,
            ...(address ? { address } : {}),
          },
        }).unwrap()

        message.success(t('location.update-success'))
      } else {
        await createLocation({
          ...values,
          name: values.name,
          metadata: {
            tags,
          },
          businessUnit: {
            id: selectedLocationBusinessUnit,
          } as BusinessUnit,
          ...(values.responsible?.id ? { responsible: values.responsible } : { responsible: null }),
          ...(address ? { address } : {}),
        }).unwrap()
        message.success(t('location.create-success'))
      }
      if (!adminError) {
        const isFirstLocation = locations?.length == 0
        navigate(
          { pathname: routes.organizationSettings.locations.base },
          isFirstLocation ? { state: { finishOnboarding: true } } : undefined,
        )
      }
    } catch (err) {
      message.error(err?.data?.message || t('location.error'))
    } finally {
      setIsFormLoading(false)
    }
  }

  async function onFormFinish(values: Store): Promise<void> {
    const valid = await locationForm.validateFields()
    if ((location && location.id) || !isFeatureEnabled('business-units')) {
      await handleUpsertLocation(values)
    } else {
      if (valid) {
        const user = users?.find((user) => user.id === values.responsible.id)
        const businessUnit = businessUnits.find(
          (businessUnit) => businessUnit.id === selectedLocationBusinessUnit,
        )
        setIsFormValid(true)
        setFormValues(values)
        setLocationDetails({
          name: values.name,
          responsibleUser: user?.email ?? '',
          businessUnitName: businessUnit?.title ?? '',
        })
        setOpenConfirmationModal(!openConfirmationModal)
      }
    }
  }

  function selectAddress(address: string): void {
    setAddress(address)
  }

  useEffect(() => {
    locationForm.setFieldsValue({ businessUnit: selectedLocationBusinessUnit })
  }, [selectedLocationBusinessUnit])

  useEffect(() => {
    reset()
    if (!id) {
      resetLog()
    }
  }, [])

  useEffect(() => {
    locationForm.resetFields()
    if (location?.businessUnit) {
      setSelectedLocationBusinessUnit(location.businessUnit?.id)
    } else {
      if (selectedBusinessUnit?.id) {
        setSelectedLocationBusinessUnit(selectedBusinessUnit?.id)
      }
    }
    setAddress(location?.address || '')
  }, [location])

  useEffect(() => {
    if (location) {
      setSelectedLocationBusinessUnit(location.businessUnit?.id)
    } else if (businessUnitId) {
      setSelectedLocationBusinessUnit(businessUnitId)
    }
  }, [businessUnits])

  const { TabPane } = Tabs

  return (
    <>
      <div className={classes.container}>
        <Spin spinning={loading || isFormLoading || appLoading} wrapperClassName={classes.spinner}>
          <div className={classes.subContainer}>
            <div className={classes.headerRow}>
              <Title className={classes.formTitle}>
                {location
                  ? `${t('actions.edit.title')} ${location.name}`
                  : t('location.create-location')}
              </Title>
            </div>
            <Col span={24}>
              <Button
                prefixIcon={<HiOutlineBookOpen size={20} />}
                href={t('intercom.log.locations')}
                type="link"
                target="_blank"
                rel="noreferrer"
                className={classes.learnLink}
              >
                {t('locations.learn-create')}
              </Button>
            </Col>
            <Tabs defaultActiveKey={'settings'}>
              <TabPane tab={t('nav.settings')} key={'settings'}>
                {location && !location.active && (
                  <Alert type={'warning'} className={classes.alert}>
                    <Text size="xl">{t('location.disable.alert')}</Text>
                  </Alert>
                )}
                <Form
                  category={AnalyticsCategories.LOCATIONS}
                  layout="vertical"
                  disabled={location && !location.active}
                  initialValues={{
                    ...location,
                    tags: location?.metadata?.tags ?? [],
                    active: location?.active ?? true,
                  }}
                  form={locationForm}
                  onFinish={onFormFinish}
                  className={classes.formContainer}
                >
                  <Form.Item label="ID" name="id" hidden />
                  <Form.Item
                    label={t('name')}
                    name="name"
                    rules={[{ required: true, message: t('location.name-required') }]}
                    data-cy="location-name"
                    className={classes.formItem}
                    extra={t('location.create-descriptions.name')}
                  >
                    <InputField />
                  </Form.Item>
                  {location?.metadata?.externalId && (
                    <Form.Item className={classes.formItem} label={t('location.product-code')}>
                      <InputField disabled value={location?.metadata?.externalId} />
                    </Form.Item>
                  )}
                  <Form.Item
                    label={t('address')}
                    name="address"
                    hasFeedback={hasFeedback}
                    required={true}
                    rules={[
                      {
                        async validator(_, value) {
                          if (value) {
                            return searchAddress(value)
                          } else {
                            throw new Error(t('location.address-required'))
                          }
                        },
                      },
                    ]}
                    data-cy="location-address"
                    className={classes.formItem}
                    extra={t('location.create-descriptions.address')}
                  >
                    <AutoComplete
                      options={addresses.map((address) => ({
                        value: address.title,
                        label: address.title,
                      }))}
                      onSelect={selectAddress}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('location.description')}
                    name="description"
                    data-cy="location-description"
                    className={classes.formItem}
                    extra={t('location.create-descriptions.description')}
                  >
                    <InputField type="textarea" />
                  </Form.Item>
                  {isFeatureEnabled('business-units') && (
                    <Form.Item
                      label={t('business-unit.name')}
                      className={classes.formItem}
                      data-cy="business-unit"
                      required={true}
                      extra={t('location.create-descriptions.business-unit')}
                    >
                      <BusinessUnitsDropdown
                        defaultValue={selectedLocationBusinessUnit}
                        businessUnits={businessUnits}
                        showNoParent={false}
                        onChange={(value) => setSelectedLocationBusinessUnit(Number(value))}
                        value={selectedLocationBusinessUnit}
                        size="middle"
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    label={t('location.responsible-user')}
                    name={['responsible', 'id']}
                    required
                    data-cy="responsible-user"
                    className={classes.formItem}
                    extra={t('location.create-descriptions.responsible-user')}
                  >
                    <Select<string, OptionProps>
                      showSearch
                      size="middle"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        if (!option || !option.children) {
                          return false
                        }
                        return (
                          (option.children as string).toLowerCase().indexOf(input.toLowerCase()) >=
                          0
                        )
                      }}
                    >
                      {users?.map((user) => (
                        <Select.Option key={user.id} value={user.id}>
                          {user.email}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="tags"
                    label={t('location.location-tags')}
                    valuePropName="tags"
                    data-cy="location-tags"
                    className={classes.formItem}
                  >
                    <TagInput />
                  </Form.Item>
                  <div className={classes.actions}>
                    <Button
                      category={AnalyticsCategories.LOCATIONS}
                      disabled={location && !location?.active}
                      action="save"
                      type="primary"
                      htmlType="submit"
                      className={classes.submitButton}
                      data-cy="save-location-btn"
                    >
                      {t('actions.save')}
                    </Button>
                    <Button
                      category={AnalyticsCategories.LOCATIONS}
                      disabled={location && !location?.active}
                      action="cancel"
                      type="secondary"
                      onClick={goOnePageBack}
                      className={classes.cancelButton}
                    >
                      {t('actions.cancel')}
                    </Button>
                  </div>
                </Form>
              </TabPane>

              {location && (
                <TabPane
                  disabled={!(id && isManagerOrAdmin)}
                  tab={t('location.settings.advanced')}
                  key={'advanced'}
                  data-cy={'advanced-locations-tab'}
                >
                  {id && isManagerOrAdmin && (
                    <div>
                      {location?.metadata?.demo ? (
                        <>
                          <Text size="xl">{t('location.delete.demo')}</Text>
                          <Button
                            category={AnalyticsCategories.LOCATIONS}
                            action="upgrade"
                            onClick={() => navigate(routes.accountBilling.subscription)}
                            type="primary"
                            className={classes.dangerZoneButton}
                          >
                            {t('location.delete.upgrade')}
                          </Button>
                        </>
                      ) : (
                        <>
                          {location.active && (
                            <AdvancedOption
                              title={t('location.disable.title')}
                              subtitle={
                                <>
                                  <Text size="xl">
                                    <Trans
                                      t={t}
                                      i18nKey="location.disable.subtitle.title"
                                      values={{ name: location.name }}
                                    />
                                  </Text>
                                  <ul className={classes.deactivateList}>
                                    <li>
                                      <span>{t('location.disable.subtitle.line-1')}</span>
                                    </li>
                                    <li>
                                      <span>{t('location.disable.subtitle.line-2')}</span>
                                    </li>
                                    <li>
                                      <span>{t('location.disable.subtitle.line-3')}</span>
                                    </li>
                                  </ul>
                                </>
                              }
                              question={t('location.disable.subtitle.question')}
                              popConfirmTitle={
                                <Text size="xl">
                                  <Trans t={t} i18nKey="location.disable.warning" />
                                </Text>
                              }
                              action={onDisableLocation}
                              btnProperties={{
                                category: AnalyticsCategories.LOCATIONS,
                                style: 'ghost',
                                icon: <HiOutlineMinusCircle />,
                              }}
                            />
                          )}
                          <AdvancedOption
                            title={t('location.delete.title')}
                            subtitle={
                              <>
                                <Text size="xl">
                                  <Trans
                                    t={t}
                                    i18nKey="location.delete.subtitle.title"
                                    values={{ name: location.name }}
                                  />
                                </Text>
                                <ul className={classes.deactivateList}>
                                  <li>
                                    <span>{t('location.delete.subtitle.line-1')}</span>
                                  </li>
                                  <li>
                                    <span>{t('location.delete.subtitle.line-2')}</span>
                                  </li>
                                </ul>
                              </>
                            }
                            question={t('location.delete.warning')}
                            popConfirmTitle={
                              <Text size="xl">
                                <Trans t={t} i18nKey="location.delete.warning" />
                              </Text>
                            }
                            action={onDeleteLocation}
                            btnProperties={{
                              category: AnalyticsCategories.LOCATIONS,
                              icon: <HiTrash />,
                            }}
                          />
                        </>
                      )}
                    </div>
                  )}
                </TabPane>
              )}
            </Tabs>
          </div>
        </Spin>
      </div>

      {isFormValid && formValues && (
        <ConfirmationModal
          openModal={openConfirmationModal}
          setOpenModal={setOpenConfirmationModal}
          title={t('location.modal.title')}
          onOk={() => handleUpsertLocation(formValues)}
          userOrLocationDetails={locationDetails}
          confirmationText={t('location.modal.create-location-confirm')}
        />
      )}
    </>
  )
}

export default UpsertLocation
