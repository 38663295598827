import React, { lazy } from 'react'
import { Navigate, RouteProps, matchPath, useLocation } from 'react-router-dom'

import { routes } from '@cozero/utils'

import { useOrgIsSupplier } from '@/hooks/useOrgIsSupplier'
import { usePricingFeature } from '@/hooks/usePricingFeature'
import { useSupplierRoute } from '@/hooks/useSupplierRoute'
import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin, getUserRole } from '@/redux/auth'

import { FallbackErrorType } from '../ErrorBoundary/types'
import { AppErrorFallback } from '../ErrorFallback/AppErrorFallback'

type FeatureRouteProps = RouteProps & {
  requiredFeature?: string | string[]
  admin?: boolean
  excludeRoles?: string[]
  children?: React.ReactNode
  moduleRoute?: string
}

const mapFeatureToPaywallPage = {
  [routes.overview.dashboard]: lazy(() => import('@/pages/Log/LogPaywall')),
  [routes.overview.digitalTwin]: lazy(() => import('@/pages/Organization/OrganizationPaywall')),
  [routes.act.base]: lazy(() => import('@/pages/Act/ActPaywall')),
  [routes.act.analytics]: lazy(() => import('@/pages/Act/Analytics/AnalyticsPaywall')),
  [routes.act.climateActions]: lazy(
    () => import('@/pages/Act/StrategyBuilder/StrategyBuilderPaywall'),
  ),
  [routes.act.targets]: lazy(() => import('@/pages/Act/Targets/TargetsPaywall')),
  [routes.log.base]: lazy(() => import('@/pages/Log/LogPaywall')),
  [routes.log.productLogs]: lazy(
    () => import('@/pages/Log/ProductsCarbonFootPrint/ProductFootprintPaywall'),
  ),
  [routes.log.corporateFootprint.base]: lazy(
    () => import('@/pages/Log/LocationLogs/CorporateFootprintPaywall'),
  ),
  [routes.organizationSettings.locations.base]: lazy(
    () => import('@/pages/Organization/OrganizationPaywall'),
  ),
  [routes.log.factors.base]: lazy(
    () => import('@/pages/Log/Factors/FactorRequests/FactorRequestsPaywall'),
  ),
  [routes.log.customers]: lazy(() => import('@/pages/Organization/Customers/CustomersPaywall')),
  [routes.log.suppliers]: lazy(() => import('@/pages/Organization/Suppliers/SuppliersPaywall')),
  [routes.log.closedPeriods.base]: lazy(() => import('@/pages/ClosedPeriods/ClosedPeriodsPaywall')),
  [routes.log.tags]: lazy(() => import('@/pages/Log/Tags/TagsPaywall')),
  [routes.share.base]: lazy(() => import('@/pages/Share/SharePages/SharePaywall')),
  [routes.share.reports.base]: lazy(() => import('@/pages/Share/Reports/ReportsPaywall')),
  [routes.share.reports.computedReport.view]: lazy(() => import('@/pages/Act/ActPaywall')),
  [routes.share.reports.computedReport.details]: lazy(() => import('@/pages/Act/ActPaywall')),
}

function isEnabled(
  requiredFeature: FeatureRouteProps['requiredFeature'],
  isFeatureEnabled: (feat: string) => boolean,
): boolean {
  if (!requiredFeature) {
    return true
  }

  if (Array.isArray(requiredFeature)) {
    return requiredFeature.some((feat) => isFeatureEnabled(feat))
  }

  return isFeatureEnabled(requiredFeature)
}

const FeatureRoute = ({
  children,
  requiredFeature,
  admin,
  excludeRoles,
}: FeatureRouteProps): JSX.Element => {
  const userRole = useAppSelector(getUserRole)
  const { isFeatureEnabled } = usePricingFeature()
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const location = useLocation()
  const orgIsSupplier = useOrgIsSupplier()
  const { isSupplierRoute } = useSupplierRoute()
  const isUserAllowed = isEnabled(requiredFeature, isFeatureEnabled)
  if (admin && !isManagerOrAdmin) {
    return <Navigate to={routes.dashboardHome.home} />
  }
  if (!isUserAllowed || (orgIsSupplier && !isSupplierRoute(location.pathname))) {
    if (location.pathname === routes.dashboardHome.home) {
      return <Navigate to={routes.log.factors.requestsPage.incomingRequest} />
    }

    const matchedPath = Object.keys(mapFeatureToPaywallPage).find((routePattern) =>
      matchPath({ path: routePattern, end: true }, location.pathname),
    )

    const PaywallPage = matchedPath ? mapFeatureToPaywallPage[matchedPath] : null

    if (!PaywallPage) {
      return (
        <AppErrorFallback
          error={new Error('No PaywallPage matched')}
          type={FallbackErrorType.GENERAL}
        />
      )
    }

    return <PaywallPage />
  }
  if (userRole && excludeRoles?.includes(userRole)) {
    return <Navigate to={routes.dashboardHome.home} />
  }

  return <>{children}</>
}

export default FeatureRoute
