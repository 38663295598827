import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd'

import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'

import { formatCompact } from '@/utils/number'
import { calculateEmissionValue } from '@/utils/recalculations'

import classes from './classes.module.less'

type SharedLifecycles = Array<{
  id?: number
  key: string
  value: number
}>

export const requestHasAllRequiredSteps = (
  requestRequiredStepsKeys: string[],
  sharedLifeCycles: SharedLifecycles,
): boolean =>
  requestRequiredStepsKeys.every((key) =>
    sharedLifeCycles.some((lf) => lf.key === key && !!lf.value),
  )

interface Props {
  productMass: number | undefined
  requestRequiredStepsKeys: string[]
  unitName: string
  sharedLifeCycles: SharedLifecycles
  totalEmissionValue?: number | null
}

function EmissionPerLifecycleTable({
  productMass,
  unitName,
  sharedLifeCycles,
  requestRequiredStepsKeys,
  totalEmissionValue,
}: Props): JSX.Element {
  const { t } = useTranslation('common')

  // unique key values from the factor request and the actual emission entries
  const mergedLifecycleKeys = Array.from(
    new Set([...requestRequiredStepsKeys, ...sharedLifeCycles.map((lc) => lc.key)]),
  )

  return (
    <Row>
      <div className={classes.lifeCycleDetailsTable}>
        <Row className={classes.tableHeader}>
          <Col>
            <Text fontWeight="medium" size="xl">
              {t('customer-requests.review-modal.emission-lifecycle-step')}
            </Text>
          </Col>
        </Row>
        <Row className={classes.subHeader} justify="space-between">
          <Col>
            <Text color="secondary" fontWeight="medium">
              {t('customer-requests.review-modal.lifecycle-steps')}
            </Text>
          </Col>
          <Col>
            <Text color="secondary" fontWeight="medium">
              {t('product.lifecycle-steps.quantity.footprint')} {`(${unitName})`}
            </Text>
          </Col>
        </Row>
        <div className={classes.tableContent}>
          {mergedLifecycleKeys?.map((key) => {
            const step = sharedLifeCycles?.find((step) => step.key === key)
            const displayValue = calculateEmissionValue(productMass, step?.value)

            return (
              <Row className={classes.tableData} key={key}>
                <Col>
                  <Text fontWeight="medium">
                    {t(
                      'product.lifecycle-steps.' +
                        (key.replace('product-', '') ?? 'product-configuration') +
                        '.title',
                    )}
                  </Text>
                </Col>
                <Col>
                  {displayValue && step ? (
                    <Text fontWeight="medium">{formatCompact(displayValue * 1000)}</Text>
                  ) : (
                    <Tag className={classes.missingDataTag} type="danger">
                      {t('general-errors.missing-data')}
                    </Tag>
                  )}
                </Col>
              </Row>
            )
          })}
        </div>
        <Row className={classes.tableFooter}>
          <Col>
            <Text fontWeight="semibold" size="xl">
              {totalEmissionValue ? formatCompact(totalEmissionValue) : '--'} {unitName}
            </Text>
          </Col>
        </Row>
      </div>
    </Row>
  )
}

export default EmissionPerLifecycleTable
