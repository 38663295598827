import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineLockClosed } from 'react-icons/hi'

import classNames from 'classnames'

import Pill from '@/atoms/Pill'

import classes from './PaywallPill.module.less'

export type PaywallPillProps = {
  variant?: 'dark' | 'light'
  className?: string
  size?: 'md' | 'sm'
  iconVariant?: 'normal' | 'none'
  textVariant?: 'normal' | 'none'
  onClick?: (args?: unknown) => void
  text?: string
}

const PaywallPill = ({
  className,
  size = 'md',
  variant = 'light',
  iconVariant = 'normal',
  textVariant = 'normal',
  onClick,
  text,
}: PaywallPillProps): JSX.Element => {
  const { t } = useTranslation()
  const pillClasses = classNames(classes.pill, classes[variant], classes[size], className)
  const icon = iconVariant === 'none' ? undefined : <HiOutlineLockClosed size={10} />
  const pillText = text ?? t('paywall.pill.label')

  return (
    <div onClick={onClick} className={onClick && classes.isClickable}>
      <Pill className={pillClasses} icon={icon}>
        {textVariant === 'none' ? null : pillText}
      </Pill>
    </div>
  )
}

export default PaywallPill
