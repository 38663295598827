import { FactorRequestToPcfBulkImportSupplierMapping } from '@prisma/client'

import {
  FactorRequestPcfDto,
  GetManyPcfBulkImportMappingsQuery,
  PaginatedQueryResult,
  PaginationDto,
  PcfBulkImportSupplierMappingDto,
  UpdateFactorRequestToPcfBulkImportSupplierMappingPcfDto,
  UpdatePcfBulkImportSupplierMappingDto,
} from '@cozero/dtos'
import { FactorRequestSupplierPcfStatus } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'
import { TAG_PCF_BULK_IMPORTS } from '../products/constants'

import {
  GET_MANY_SUPPLIER_PCF_IMPORTS,
  GET_REQUESTS_BY_PCF_ID_AND_STATUS,
  TAG_MANY_SUPPLIER_PCF_FACTOR_REQUESTS,
  TAG_SUPPLIER_PCF_BULK_IMPORT,
  UPDATE_ONE_FACTOR_REQUEST_SUPPLIER_PCF,
  UPDATE_ONE_SUPPLIER_PCF_IMPORT,
} from './constants'

const pcfBulkImportSupplierMappingRequestsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_MANY_SUPPLIER_PCF_IMPORTS]: builder.query<
      PcfBulkImportSupplierMappingDto[],
      GetManyPcfBulkImportMappingsQuery
    >({
      query: (params) => ({
        url: logApiGatewayClient.pcfBulkImportMappings.GET_MANY,
        method: 'GET',
        params,
      }),
      providesTags: () => [TAG_SUPPLIER_PCF_BULK_IMPORT],
    }),
    [GET_REQUESTS_BY_PCF_ID_AND_STATUS]: builder.query<
      PaginatedQueryResult<FactorRequestPcfDto>,
      {
        pcfBulkImportId: string
        // not supported yet
        pagination?: PaginationDto
        category: FactorRequestSupplierPcfStatus
      }
    >({
      query: ({ pcfBulkImportId, pagination, category }) => ({
        url: logApiGatewayClient.pcfBulkImportMappings.GET_REQUESTS_BY_PCF_ID_AND_STATUS.replace(
          ':id',
          pcfBulkImportId,
        ).replace(':category', category),
        method: 'GET',
        params: pagination,
      }),
      providesTags: () => [{ type: TAG_MANY_SUPPLIER_PCF_FACTOR_REQUESTS }],
    }),
    [UPDATE_ONE_FACTOR_REQUEST_SUPPLIER_PCF]: builder.mutation<
      FactorRequestToPcfBulkImportSupplierMapping,
      {
        factorRequestId: number
        pcfBulkImportMappingId: number
        data: UpdateFactorRequestToPcfBulkImportSupplierMappingPcfDto
      }
    >({
      query: ({ data, factorRequestId, pcfBulkImportMappingId }) => ({
        url: logApiGatewayClient.pcfBulkImportMappings.UPDATE_ONE_FACTOR_REQUEST_SUPPLIER_PCF.replace(
          ':id',
          pcfBulkImportMappingId.toString(),
        ).replace(':factorRequestId', factorRequestId.toString()),
        method: 'PUT',
        data,
      }),
      // TODO
      // write optimistic update
      invalidatesTags: () => [TAG_MANY_SUPPLIER_PCF_FACTOR_REQUESTS],
    }),
    [UPDATE_ONE_FACTOR_REQUEST_SUPPLIER_PCF]: builder.mutation<
      FactorRequestToPcfBulkImportSupplierMapping,
      {
        factorRequestId: number
        pcfBulkImportMappingId: number
        data: UpdateFactorRequestToPcfBulkImportSupplierMappingPcfDto
      }
    >({
      query: ({ data, factorRequestId, pcfBulkImportMappingId }) => ({
        url: logApiGatewayClient.pcfBulkImportMappings.UPDATE_ONE_FACTOR_REQUEST_SUPPLIER_PCF.replace(
          ':id',
          pcfBulkImportMappingId.toString(),
        ).replace(':factorRequestId', factorRequestId.toString()),
        method: 'PUT',
        data,
      }),
      // TODO
      // write optimistic update
      invalidatesTags: () => [TAG_MANY_SUPPLIER_PCF_FACTOR_REQUESTS],
    }),
    [UPDATE_ONE_SUPPLIER_PCF_IMPORT]: builder.mutation<
      PcfBulkImportSupplierMappingDto,
      {
        id: number
        data: UpdatePcfBulkImportSupplierMappingDto
      }
    >({
      query: ({ data, id }) => ({
        url: logApiGatewayClient.pcfBulkImportMappings.UPDATE_ONE.replace(':id', id.toString()),
        method: 'PATCH',
        data,
      }),
      // TODO
      // write pessimistic update
      invalidatesTags: () => [TAG_SUPPLIER_PCF_BULK_IMPORT, TAG_PCF_BULK_IMPORTS],
    }),
  }),
})

export const {
  useUpdateOneFactorRequestSupplierPcfMutation,
  useGetRequestsByPcfIdAndCategoryQuery,
  useGetManySupplierPcfImportsQuery,
  useUpdateOneSupplierPcfImportMutation,
} = pcfBulkImportSupplierMappingRequestsApiSlice
