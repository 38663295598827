import React from 'react'
import { HiArrowLeft, HiOutlineX } from 'react-icons/hi'

import classnames from 'classnames'

import Button from '@/atoms/Button'

import { AnalyticsCategories } from '@/constants/analyticsCategories'

import classes from './CustomModalHeader.module.less'

interface CustomHeaderProps {
  onClose: () => void
  goBack: () => void
  title: string | undefined
  showArrow?: boolean
}

const CustomHeader = ({
  onClose,
  goBack,
  title,
  showArrow = true,
}: CustomHeaderProps): JSX.Element => (
  <div className={classes.modalHeaderContainer}>
    <div
      className={classnames(classes.modalHeaderTitleContainer, {
        [classes.noArrow]: !showArrow,
      })}
    >
      {showArrow && (
        <Button
          category={AnalyticsCategories.PRODUCTS}
          type="text"
          onClick={goBack}
          size="md"
          prefixIcon={<HiArrowLeft size={24} />}
        />
      )}
      <h2>{title}</h2>
    </div>

    <Button
      category={AnalyticsCategories.PRODUCTS}
      type="text"
      onClick={onClose}
      size="md"
      prefixIcon={<HiOutlineX size={32} />}
    />
  </div>
)

export default CustomHeader
