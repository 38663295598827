/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { message } from 'antd/es'
import { RcFile } from 'antd/lib/upload/interface'

import { CalculationType } from '@prisma/client'
import { skipToken } from '@reduxjs/toolkit/query'
import AxiosRaw from 'axios'
import { omit } from 'lodash-es'

import {
  ActivityDataSource,
  Assumption,
  BulkImport,
  BulkImportErrors,
  BulkImportResponse,
  CalculationMethod,
  Category,
  ClientUserOption,
  CreateBulkLog,
  Customer,
  ImportDetails,
  Input,
  LogEntry,
  LogEntryTag,
  Page,
  PreviewCalculation,
  Product,
  ProductLogEntry,
  ProductTag,
  PutSignedUrl,
  Scope,
  Subcategory,
  Unit,
} from '@cozero/models'
import {
  calculationsApiGatewayClient,
  centralApiGatewayClient,
  logApiGatewayClient,
} from '@cozero/uris'

import { CreateProductLogEntry } from '@/pages/GenericLifecycleSteps/GenericStep'

import { ParsedProductFormValues } from '@/organisms/FactorRequestForm/types'

import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { TAG_SEARCH_LOG_ENTRIES } from '@/redux/logEntries/tags'

import {
  deleteBulkImport as deleteImport,
  getBulkImports as fetchBulkImports,
  previewBulkImportLogs as fetchPreviewBulkImportLogs,
  submitBulkImport,
  validateLogs,
} from '../backend/log'
import {
  updateProduct as editProduct,
  getProduct as fetchProduct,
  getProducts as fetchProducts,
} from '../backend/products'
import { LogContextInterface } from '../contexts/log'
import { useAppDispatch } from '../redux'
import api from '../redux/api'
import { useLazyGetUserDataQuery } from '../redux/auth'
import { useGetPaginatedLogEntriesQuery } from '../redux/logEntries'
import { TAG_LOG, useEditLogMutation, useLazyGetLogQuery } from '../redux/logs'
import { TAG_PRODUCT } from '../redux/products/constants'
import axios from '../utils/axios'
import { getUrl } from '../utils/url'

const useLog = (): LogContextInterface => {
  const dispatch = useAppDispatch()
  const [getMe] = useLazyGetUserDataQuery()
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)
  const [getLog, { data: log }] = useLazyGetLogQuery()
  const { data: logEntries } = useGetPaginatedLogEntriesQuery(
    log?.id
      ? {
          logIds: [log.id],
        }
      : skipToken,
  )
  const [editLog] = useEditLogMutation()
  const [error, setError] = useState<Error | string | undefined>()
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState<Product>()
  const [locationModalOpen, setLocationModalOpen] = useState<boolean>(false)
  const [showProductModal, setShowProductModal] = useState<boolean>(false)
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean | undefined>(false)
  const [scopes, setScopes] = useState<Scope[]>([])
  const [customers, setCustomers] = useState<Customer[]>([])
  const [currencies, setCurrencies] = useState<Unit[]>([])
  const [subcategories, setSubcategories] = useState<Subcategory[]>([])
  const [units, setUnits] = useState<Unit[]>([])
  const [validating, setValidating] = useState<boolean>(false)
  const [bulkImportId, setBulkImportId] = useState<number | undefined>()
  const [importKey, setImportKey] = useState<string | undefined>()
  const [logs, setLogs] = useState<CreateBulkLog[]>([])
  const [templateErrors, setTemplateErrors] = useState<BulkImportErrors>()
  const [totalResults, setTotalResults] = useState<number>(1)
  const [tags, setTags] = useState<LogEntryTag[]>([])
  const [productTags, setProductTags] = useState<ProductTag[]>([])
  const [hasProductLogEntries, setHasProductLogEntries] = useState<boolean>(false)

  const { t } = useTranslation('common')

  /** @deprecated use RTK Query version */
  const getTags = async (subcategoryId?: number | undefined): Promise<LogEntryTag[]> => {
    let tags: LogEntryTag[] = []
    try {
      setLoading(true)
      const url = logApiGatewayClient.tags.GET_MANY
      const tagsResponse = await axios.get(url, {
        params: {
          businessUnitId: selectedBusinessUnit?.id,
          ...(subcategoryId ? { subcategoryId } : {}),
        },
      })
      tags = tagsResponse.data as LogEntryTag[]
      setTags(tags)
      setLoading(false)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
    return tags
  }

  const getProductTags = async (pattern = ''): Promise<ProductTag[]> => {
    let tags: LogEntryTag[] = []
    try {
      setLoading(true)
      const url = centralApiGatewayClient.productTags.GET_MANY
      const queryString = pattern ? `?name=${pattern}` : ''
      const tagsResponse = await axios.get(`${url}${queryString}`)
      tags = tagsResponse.data as LogEntryTag[]
      setProductTags(tags)
      setLoading(false)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
    return tags
  }

  /** @deprecated use RTK Query version */
  const createTag = async (payload: Partial<LogEntryTag>): Promise<LogEntryTag | undefined> => {
    let tag: LogEntryTag
    try {
      setLoading(true)
      const tagsResponse = await axios.post(logApiGatewayClient.tags.CREATE, payload)
      tag = tagsResponse.data as LogEntryTag
      return tag
    } catch (e) {
      setError(e.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const updateTag = async (
    id: number,
    payload: Partial<LogEntryTag>,
  ): Promise<LogEntryTag | undefined> => {
    let tag: LogEntryTag
    try {
      setLoading(true)
      const tagsResponse = await axios.put(
        logApiGatewayClient.tags.UPDATE_ONE.replace(':id', `${id}`),
        payload,
      )
      tag = tagsResponse.data as LogEntryTag
      setLoading(false)
      return tag
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const createProductTag = async (name: string): Promise<ProductTag | undefined> => {
    let tag: LogEntryTag
    try {
      setLoading(true)
      const tagsResponse = await axios.post(centralApiGatewayClient.productTags.CREATE, { name })
      tag = tagsResponse.data as LogEntryTag
      return tag
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  /** @deprecated use RTK Query version */
  const deleteTag = async (tagId: string | number): Promise<void> => {
    try {
      setLoading(true)
      await axios.delete(logApiGatewayClient.tags.DELETE_ONE.replace(':id', `${tagId}`))
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const deleteProductTag = async (tagId: string | number): Promise<void> => {
    try {
      setLoading(true)
      await axios.delete(centralApiGatewayClient.productTags.DELETE_ONE.replace(':id', `${tagId}`))
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const submitUpdateLogEntry = async (
    logEntry: Omit<Partial<LogEntry>, 'inputs' | 'tags'> & {
      inputs: Partial<Input>[]
      tags: Partial<LogEntryTag>[]
    },
  ): Promise<void> => {
    try {
      const url = getUrl(
        logApiGatewayClient.logEntries.UPDATE_ONE.replace(':id', `${logEntry?.id}`),
      )
      const slimLogEntry = omit(logEntry, [
        'organizationEmissions',
        'productEmissions',
        'subcategory',
        'activityDataSource',
        'calculationMethod',
        'territory',
      ])
      const body = {
        ...slimLogEntry,
        subcategoryId: logEntry.subcategoryId,
        activityDataSourceId: logEntry.activityDataSourceId,
        calculationMethodId: logEntry.calculationMethodId,
        territoryId: logEntry.territoryId,
      }
      const { data: newLogEntry } = await axios.put(url.toString(), body)

      if (newLogEntry && log) {
        const existingLogEntryIdx = logEntries?.data?.findIndex(
          (x: LogEntry) => x.id.toString() === newLogEntry.id.toString(),
        )

        if (existingLogEntryIdx && existingLogEntryIdx !== -1) {
          const deepCopyLog = JSON.parse(JSON.stringify(log || {}))
          const oldLogEntry = (deepCopyLog?.logEntries as LogEntry[])[existingLogEntryIdx]
          deepCopyLog.logEntries[existingLogEntryIdx] = {
            ...oldLogEntry,
            ...newLogEntry,
          }
          editLog({ id: log.id, log: deepCopyLog })
        }
        t('actions.update.item', { item: t('log.entry.title') })
      }
    } catch (err) {
      throw new Error(err)
    }
  }

  const submitLogEntry = async (
    logEntry: Omit<Partial<LogEntry>, 'inputs' | 'tags'> & {
      inputs: Partial<Input>[]
      tags: Partial<LogEntryTag>[]
    },
  ): Promise<void> => {
    try {
      const url = getUrl(logApiGatewayClient.logEntries.CREATE)
      const businessUnitId = selectedBusinessUnit ? selectedBusinessUnit?.id : undefined
      await axios.post(url.toString(), {
        ...logEntry,
        businessUnitId,
      })

      message.success(t('log.entry.new'))
    } catch (err) {
      setError(err.message)
      throw new Error(err)
    }
  }

  const deleteLogEntry = async (logId: number, id: number): Promise<void> => {
    setLoading(true)
    try {
      if (id) {
        const businessUnitId = selectedBusinessUnit ? selectedBusinessUnit?.id : undefined
        const url = getUrl(logApiGatewayClient.logEntries.DELETE_ONE.replace(':id', id.toString()))
        await axios.delete(url.toString(), { params: { businessUnitId: businessUnitId } })
        dispatch(api.util.invalidateTags([{ type: TAG_LOG, id: logId }, TAG_SEARCH_LOG_ENTRIES]))
      }
    } catch (e) {
      message.error(t('log.entry.error.delete'))
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const getProduct = async (locationId: number): Promise<Product | void> => {
    try {
      setLoading(true)
      const product = await fetchProduct(locationId)
      setProduct(product)
      return product
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const getProducts = async (): Promise<Product[] | void> => {
    try {
      return await fetchProducts({})
    } catch (e) {
      setError(e)
    }
  }

  const getScopes = async (): Promise<void> => {
    try {
      setLoading(true)
      const { data: scopes } = await axios.get<Scope[]>(logApiGatewayClient.scopes.GET_MANY)

      setScopes(scopes)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  /** @deprecated Please use RTK QUERY `useGetCalculationMethodsQuery` */
  const getCalculationMethods = async (
    subcategoryId: number,
    activityDataSourceId?: number,
  ): Promise<CalculationMethod[] | undefined> => {
    try {
      setLoading(true)
      const { data: calculationMethods } = await axios.get<CalculationMethod[]>(
        logApiGatewayClient.calculation_methods.GET_MANY,
        {
          params: { subcategoryId: subcategoryId.toString(), activityDataSourceId },
        },
      )

      return calculationMethods
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  /** @deprecated Please use RTK QUERY `useGetActivityDataSourcesTreeQuery` */
  const getActivityDataSources = async (
    subcategoryId: number,
    calculationMethodId?: number,
  ): Promise<ActivityDataSource[] | undefined> => {
    try {
      setLoading(true)
      const url = `${logApiGatewayClient.activity_data_sources.GET_MANY}`
      const { data: activityDataSources } = await axios.get<ActivityDataSource[]>(url, {
        params: { subcategoryId, ...(calculationMethodId ? { calculationMethodId } : {}) },
      })
      return activityDataSources
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const getActivityDataSourcesWithoutStructure = async (
    subcategoryId?: number,
    calculationMethodId?: number,
    parentId?: number,
  ): Promise<ActivityDataSource[] | undefined> => {
    try {
      setLoading(true)
      const url = `${logApiGatewayClient.activity_data_sources.GET_MANY_WITHOUT_STRUCTURE}`
      const { data: activityDataSources } = await axios.get<ActivityDataSource[]>(url, {
        params: {
          ...(subcategoryId ? { subcategoryId } : {}),
          ...(calculationMethodId ? { calculationMethodId } : {}),
          ...(parentId ? { parentId } : {}),
        },
      })
      return activityDataSources
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const getActivityDataSourcePathStructure = async (
    adsId: number,
  ): Promise<{ adsStructure: number[] }> => {
    try {
      const url = `${logApiGatewayClient.activity_data_sources.ADS_PATH_STRUCTURE}`.replace(
        ':id',
        adsId.toString(),
      )
      const { data: activityDataSources } = await axios.get<{ adsStructure: number[] }>(url)
      return activityDataSources
    } catch (e) {
      setError(e)
      throw new Error(e)
    }
  }

  const createScopedActivityDataSource = async (
    body: ParsedProductFormValues,
  ): Promise<ActivityDataSource> => {
    try {
      setLoading(true)
      const url = `${logApiGatewayClient.activity_data_sources.CREATE_SCOPED}`
      const { data: activityDataSources } = await axios.post<ActivityDataSource>(url, body)
      message.success(t('log.bulk.create-scoped-ads.success'))
      return activityDataSources
    } catch (e) {
      setError(e)
      const errorMessage = t('log.bulk.create-scoped-ads.error')
      message.error(errorMessage)
      throw new Error(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const getAssumptions = async (
    productTypeId: number,
    lifecycleStepKey: string,
  ): Promise<Assumption[] | undefined> => {
    try {
      setLoading(true)
      const url = `${
        centralApiGatewayClient.assumptions.GET_MANY
      }?productTypeId=${productTypeId.toString()}&lifecycleStepKey=${lifecycleStepKey}`
      const { data: activityDataSources } = await axios.get<Assumption[]>(url)
      return activityDataSources
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  function resetLog(): void {
    setProduct(undefined)
  }

  async function getCustomers(): Promise<Customer[] | void> {
    try {
      const { data: customers } = await axios.get<Customer[]>(
        centralApiGatewayClient.customers.GET_MANY,
        {
          params: {
            businessUnitId: selectedBusinessUnit?.id,
          },
        },
      )
      setCustomers(customers)
      return customers
    } catch (e) {
      setError(e)
    }
  }

  async function createCustomer(body: Partial<Customer>): Promise<Customer | void> {
    try {
      return (await axios.post(centralApiGatewayClient.customers.CREATE, body)).data
    } catch (e) {
      setError(e)
    }
  }

  async function editCustomer(body: Partial<Customer>): Promise<void> {
    try {
      if (body.id) {
        await axios.put(
          centralApiGatewayClient.customers.UPDATE_ONE.replace(':id', body.id.toString()),
          body,
        )
      }
    } catch (e) {
      setError(e)
    }
  }

  async function deleteCustomer(id: number): Promise<void> {
    try {
      await axios.delete(centralApiGatewayClient.customers.DELETE_ONE.replace(':id', id.toString()))
    } catch (e) {
      setError(e)
    }
  }

  const uploadBulkImport = async (importKey: string): Promise<void | BulkImport> => {
    if (bulkImportId) {
      const bulkImport = await submitBulkImport(importKey, bulkImportId)
      setBulkImportId(undefined)
      setLogs([])
      setImportKey(undefined)
      setError(undefined)
      setValidating(false)
      return bulkImport
    }
  }

  async function getCurrencies(): Promise<void> {
    try {
      const { data: currencies } = await axios.get<Unit[]>(logApiGatewayClient.units.GET_MANY, {
        params: {
          type: 'CURRENCY',
        },
      })
      setCurrencies(currencies)
    } catch (e) {
      setError(e)
    }
  }

  const getUnits = async (): Promise<void> => {
    try {
      const { data } = await axios.get<Unit[]>(logApiGatewayClient.units.GET_MANY)
      setUnits(data)
    } catch (e) {
      setError(e)
    }
  }

  const getDenominatorFromEf = async (activityDataSourceId: number): Promise<void> => {
    try {
      const { data } = await axios.get<Unit[]>(logApiGatewayClient.units.GET_DENOMINATOR_FROM_EF, {
        params: { activityDataSourceId },
      })
      setUnits(data)
    } catch (e) {
      setError(e)
    }
  }

  const getBulkImportsPutUrl = async (filesNames: string[]): Promise<PutSignedUrl[]> => {
    return (
      await axios.get(
        `${logApiGatewayClient.bulkImport.SIGNED_PUT_URL}?fileNames=${encodeURIComponent(
          JSON.stringify(filesNames),
        )}`,
      )
    ).data
  }

  const uploadBulkImportFile = async (bulkImportFile: {
    name: string
    fileObj?: RcFile
    signedUrl: string
    path: string
  }): Promise<{ path: string }> => {
    try {
      await AxiosRaw.put(
        bulkImportFile.signedUrl,
        (bulkImportFile.fileObj as unknown as { originFileObj: File })?.originFileObj,
        {
          headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
        },
      )
      return { path: bulkImportFile.path }
    } catch (error) {
      setError(error)
      throw error
    }
  }

  const validateBulkImport = async (
    bulkImportFile: {
      name: string
      fileObj?: RcFile
      signedUrl: string
      path: string
    },
    categoryId: number,
    preprocessor: CalculationType = CalculationType.COZERO,
  ): Promise<undefined | BulkImportResponse> => {
    try {
      setImportKey(undefined)
      setValidating(true)
      setTemplateErrors(undefined)
      setBulkImportId(undefined)
      const { path } = await uploadBulkImportFile(bulkImportFile)
      const logs: BulkImportResponse = await validateLogs(
        path,
        bulkImportFile.name,
        categoryId,
        preprocessor,
      )
      setBulkImportId(logs.id)
      setImportKey(logs.importKey)
      return logs
    } catch (e) {
      setError(e)
    }
  }

  const checkValidateBulkImport = async (id: number): Promise<boolean> => {
    try {
      const { data } = await axios.get(
        logApiGatewayClient.bulkImport.CHECK_VALIDATE.replace(':id', `${id}`),
      )

      setValidating(data?.validating ?? false)
      return data?.validating ?? false
    } catch (e) {
      setError(e)
      return false
    }
  }

  const previewBulkImportLogs = async (
    importKey: string,
    page: number,
    pageSize: number,
  ): Promise<void> => {
    try {
      const results = await fetchPreviewBulkImportLogs(importKey, page, pageSize)
      if (results.errors) {
        setTemplateErrors(results.errors)
      } else {
        setLogs(results?.logs || [])
        setTemplateErrors(undefined)
        setTotalResults(results.totalResults ?? 1)
      }
    } catch (e) {
      // fallback in case the preview times out at the moment
      setTemplateErrors([] as BulkImportErrors)
      setError(e)
    }
  }

  const getBulkImports = async (pageNumber: number): Promise<Page<ImportDetails> | void> => {
    try {
      return await fetchBulkImports(pageNumber)
    } catch (e) {
      setError(e)
    }
  }

  const deleteBulkImport = async (bulkImportId?: number): Promise<void> => {
    try {
      if (bulkImportId) {
        await deleteImport(bulkImportId)
      }
    } catch (e) {
      setError(e)
    }
  }

  const getTemplate = async (category: Category): Promise<void> => {
    return axios.post(logApiGatewayClient.importTemplates.GENERATE_ONE, {
      categoryId: category.id,
    })
  }

  const updateProduct = async (product: Partial<Product>): Promise<void> => {
    try {
      setLoading(true)
      await editProduct(product)
      await getMe()
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const openDatePicker = (open?: boolean): void => setIsDatePickerOpen(open)

  async function getUserOptionsForLogCreation(
    subcategoryId: number,
    calculationMethodId: number,
    activityDataSourceId: number,
  ): Promise<ClientUserOption[]> {
    try {
      const { data } = await axios.get<ClientUserOption[]>(
        logApiGatewayClient.userOptions.GET_FOR_LOG_CREATION,
        {
          params: {
            subcategory: subcategoryId,
            activityDataSourceId,
            calculationMethodId,
          },
        },
      )
      return data
    } catch (e) {
      setError(e)
      return []
    }
  }

  /** @deprecated use RTK query `useGetSubcategoriesQuery` (Warning: Make sure to use selected business unit id by default) */
  const getSubcategories = React.useCallback(
    async (
      categoryId?: number,
      onlyWithLogsAssociated = false,
      businessUnitId?: number,
      activityDataSourceIds?: number[],
    ): Promise<void> => {
      try {
        setLoading(true)
        const { data: subcategories } = await axios.get<Subcategory[]>(
          logApiGatewayClient.subcategories.GET_MANY,
          {
            params: {
              categoryId,
              onlyWithLogsAssociated,
              businessUnitId: businessUnitId ?? selectedBusinessUnit?.id,
              activityDataSourceIds,
            },
          },
        )
        setSubcategories(subcategories)
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    },
    [selectedBusinessUnit?.id],
  )

  const getLogEntry = async (id: number): Promise<LogEntry | void> => {
    try {
      setLoading(true)
      const { data: logEntry } = await axios.get<LogEntry>(
        logApiGatewayClient.logEntries.GET_ONE.replace(':id', `${id}`),
      )
      return logEntry
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const getProductLogEntry = async (id: number): Promise<ProductLogEntry | void> => {
    try {
      setLoading(true)
      const { data: productLogEntry } = await axios.get<ProductLogEntry>(
        logApiGatewayClient.productLogEntries.GET_ONE.replace(':id', `${id}`),
      )
      return productLogEntry
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const previewProductLogEntryCalculations = async (
    logEntryId: number,
  ): Promise<PreviewCalculation[] | void> => {
    const url = `${calculationsApiGatewayClient.preview.CALCULATE_LOGS}?entryType=product`
    try {
      const { data: previewData } = await axios.post<PreviewCalculation[]>(url, {
        logEntryIds: [logEntryId],
      })
      return previewData
    } catch (e) {
      setError(e)
    }
  }

  const createManyProductLogEntries = async (
    body: CreateProductLogEntry[],
  ): Promise<ProductLogEntry[] | void> => {
    try {
      setLoading(true)
      const { data: productLogEntries } = await axios.post<ProductLogEntry[]>(
        logApiGatewayClient.productLogEntries.CREATE_MANY,
        body.map((x) => ({ ...x, activityDataSourceId: x.activityDataSourceId.at(-1) })),
      )
      return productLogEntries
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const getProductEmissions = async (
    productId: number,
  ): Promise<{ _sum: { value: number } } | void> => {
    try {
      setLoading(true)
      const { data: productEmissionsSum } = await axios.get<{ _sum: { value: number } }>(
        calculationsApiGatewayClient.calculations.GET_PRODUCT_EMISSIONS_SUM,
        { params: { productId } },
      )
      return productEmissionsSum
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const updateProductLogEntry = async (
    productId: number,
    id: number,
    body: CreateProductLogEntry,
  ): Promise<ProductLogEntry[] | void> => {
    try {
      setLoading(true)
      const { data: productLogEntry } = await axios.put<ProductLogEntry[]>(
        logApiGatewayClient.productLogEntries.UPDATE_ONE.replace(':id', id.toString()),
        { ...body, activityDataSourceId: body.activityDataSourceId.at(-1) },
      )
      dispatch(api.util.invalidateTags([{ type: TAG_PRODUCT, id: productId }]))
      return productLogEntry
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const deleteProductLogEntry = async (
    productId: number,
    id: number,
  ): Promise<ProductLogEntry[] | void> => {
    try {
      setLoading(true)
      const { data: productLogEntry } = await axios.delete<ProductLogEntry[]>(
        logApiGatewayClient.productLogEntries.DELETE_ONE.replace(':id', id.toString()),
      )
      dispatch(api.util.invalidateTags([{ type: TAG_PRODUCT, id: productId }]))
      return productLogEntry
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  const getHasProductLogEntries = async (): Promise<void> => {
    try {
      const businessUnitId = selectedBusinessUnit ? selectedBusinessUnit?.id : undefined
      setLoading(true)
      const { data: productLogEntriesExist } = await axios.get<boolean>(
        logApiGatewayClient.productLogEntries.EXISTS,
        { params: { businessUnitId } },
      )
      setHasProductLogEntries(productLogEntriesExist)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  function reset(): void {
    setError(undefined)
    setLoading(false)
  }

  return {
    getUserOptionsForLogCreation,
    error,
    loading,
    getProduct,
    getProducts,
    product,
    resetLog,
    scopes,
    getScopes,
    getCustomers,
    createCustomer,
    editCustomer,
    deleteCustomer,
    customers,
    updateProduct,
    validateBulkImport,
    uploadBulkImportFile,
    previewBulkImportLogs,
    uploadBulkImport,
    getTemplate,
    getBulkImports,
    deleteBulkImport,
    locationModalOpen,
    setLocationModalOpen,
    showProductModal,
    setShowProductModal,
    isDatePickerOpen,
    openDatePicker,
    deleteLogEntry,
    submitLogEntry,
    getCalculationMethods,
    getActivityDataSources,
    getActivityDataSourcesWithoutStructure,
    getActivityDataSourcePathStructure,
    submitUpdateLogEntry,
    getCurrencies,
    currencies,
    getSubcategories,
    subcategories,
    getLogEntry,
    getProductLogEntry,
    reset,
    getBulkImportsPutUrl,
    getTags,
    createTag,
    deleteTag,
    updateTag,
    units,
    getUnits,
    checkValidateBulkImport,
    setValidating,
    validating,
    bulkImportId,
    logs,
    templateErrors,
    importKey,
    totalResults,
    tags,
    getProductTags,
    productTags,
    createProductTag,
    deleteProductTag,
    createManyProductLogEntries,
    getAssumptions,
    updateProductLogEntry,
    deleteProductLogEntry,
    getHasProductLogEntries,
    hasProductLogEntries,
    previewProductLogEntryCalculations,
    getProductEmissions,
    getDenominatorFromEf,
    createScopedActivityDataSource,
  }
}

export default useLog
