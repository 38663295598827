export const GET_SUPPLIER_BULK_REQUEST_TEMPLATE = 'getSupplierBulkRequestTemplate'
export const CREATE_SUPPLIER_BULK_REQUEST = 'createSupplierBulkRequest'
export const GET_SIGNED_URL = 'getSupplierBulkRequestSignedUrls'
export const GET_SUPPLIER_BULK_REQUEST = 'getSupplierBulkRequests'
export const GET_SUPPLIER_BULK_REQUEST_BY_ID = 'getSupplierBulkRequestById'
export const UPDATE_ONE_SUPPLIER_BULK_REQUEST = 'updateOneSupplierBulkRequest'
export const CREATE_PCF_BULK_SUPPLIER = 'createPcfBulkImportSupplierMapping'

export const TAG_SUPPLIER_BULK_REQUEST = 'tagSupplierBulkRequests'
export const REUPLOAD_FAILED_FILE = 'reuploadFailedFile'
