import {
  FactorRequestWithResponsibleUserDto,
  ProductDetailsSupplierPcfDto,
  SubmitGhgFactorRequestWithResponseDto,
} from '@cozero/dtos'
import { FactorRequest, FactorRequestResponse } from '@cozero/models'
import { centralApiGatewayClient, logApiGatewayClient } from '@cozero/uris'

import { ParsedFormValues } from '@/organisms/FactorRequestForm/types'

import apiSlice from '../api'
import { TAG_MANY_SUPPLIER_PCF_FACTOR_REQUESTS } from '../pcfBulkImportSupplierMapping/constants'
import { TAG_ACTIVE_PRODUCTS } from '../products/constants'

import {
  CREATE_CBAM_PROOF,
  CREATE_ONE_FACTOR_REQUEST,
  DELETE_ONE_FACTOR_REQUEST,
  FIND_FACTOR_REQUEST,
  FIND_FACTOR_REQUEST_SUPPLIER,
  GET_PCF_PRODUCT_MATCHES,
  SUBMIT_GHG_REQUEST_WITH_RESPONSE,
  TAG_MANY_FACTOR_REQUESTS,
  TAG_MANY_INCOMING_FACTOR_REQUEST_ROWS,
  TAG_MANY_OUTGOING_FACTOR_REQUEST_ROWS,
  TAG_ONE_FACTOR_REQUEST,
  UPDATE_ONE_FACTOR_REQUEST,
} from './constants'

const factorsRequestsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [FIND_FACTOR_REQUEST]: builder.query<FactorRequest[], string>({
      query: (status) => ({
        url: logApiGatewayClient.factorRequests.GET_MANY,
        method: 'GET',
        params: {
          status,
        },
      }),
      providesTags: () => [{ type: TAG_MANY_FACTOR_REQUESTS }],
    }),
    [FIND_FACTOR_REQUEST_SUPPLIER]: builder.query<FactorRequest[], string>({
      query: (email) => ({
        url: centralApiGatewayClient.suppliers.GET_FACTOR_REQUESTS,
        method: 'GET',
        params: {
          supplier: email,
        },
      }),
    }),
    [DELETE_ONE_FACTOR_REQUEST]: builder.mutation<FactorRequest, number>({
      query: (id) => ({
        url: logApiGatewayClient.factorRequests.DELETE_ONE.replace(':id', id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [
        TAG_MANY_OUTGOING_FACTOR_REQUEST_ROWS,
        { type: TAG_ONE_FACTOR_REQUEST, id: result?.id },
      ],
    }),
    [UPDATE_ONE_FACTOR_REQUEST]: builder.mutation<
      FactorRequest,
      Partial<FactorRequest> & { id: number; denominatorUnitId: number | null }
    >({
      query: ({ id, ...request }) => ({
        url: logApiGatewayClient.factorRequests.UPDATE_ONE.replace(':id', id.toString()),
        method: 'PUT',
        data: request,
      }),
      invalidatesTags: (result) => [
        TAG_MANY_OUTGOING_FACTOR_REQUEST_ROWS,
        TAG_MANY_INCOMING_FACTOR_REQUEST_ROWS,
        { type: TAG_ONE_FACTOR_REQUEST, id: result?.id },
        TAG_MANY_SUPPLIER_PCF_FACTOR_REQUESTS,
      ],
    }),
    [CREATE_ONE_FACTOR_REQUEST]: builder.mutation<
      FactorRequestWithResponsibleUserDto,
      ParsedFormValues & { businessUnitId: number }
    >({
      query: (input) => ({
        url: logApiGatewayClient.factorRequests.CREATE,
        method: 'POST',
        data: input,
      }),
      invalidatesTags: () => [TAG_MANY_OUTGOING_FACTOR_REQUEST_ROWS],
    }),
    [CREATE_CBAM_PROOF]: builder.mutation<void, number[]>({
      query: (factorRequestIds) => ({
        url: logApiGatewayClient.factorRequests.CREATE_CBAM_PROOF_PDF,
        method: 'POST',
        data: {
          factorRequestIds,
        },
      }),
    }),
    [GET_PCF_PRODUCT_MATCHES]: builder.query<ProductDetailsSupplierPcfDto[], number>({
      query: (id) => ({
        url: logApiGatewayClient.productRequestMatching.GET_PCF_PRODUCT_MATCHES_FOR_ONE.replace(
          ':id',
          id.toString(),
        ),
        method: 'GET',
      }),
      providesTags: () => [{ type: TAG_ACTIVE_PRODUCTS }],
    }),
    [SUBMIT_GHG_REQUEST_WITH_RESPONSE]: builder.mutation<
      { factorRequest: FactorRequest; factorRequestResponse: FactorRequestResponse },
      {
        factorRequestId: number
        denominatorUnitId: number
        data: SubmitGhgFactorRequestWithResponseDto
      }
    >({
      query: ({ factorRequestId, denominatorUnitId, data }) => ({
        url: logApiGatewayClient.factorRequests.SUBMIT_GHG_WITH_RESPONSE.replace(
          ':id',
          factorRequestId.toString(),
        ).replace(':denominatorUnitId', denominatorUnitId.toString()),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (result) => [
        TAG_MANY_OUTGOING_FACTOR_REQUEST_ROWS,
        TAG_MANY_INCOMING_FACTOR_REQUEST_ROWS,
        TAG_MANY_SUPPLIER_PCF_FACTOR_REQUESTS,
        { type: TAG_ONE_FACTOR_REQUEST, id: result?.factorRequest.id },
      ],
    }),
  }),
})

export const {
  useLazyFindFactorRequestQuery,
  useLazyFindFactorRequestSupplierQuery,
  useFindFactorRequestByIdQuery,
  useGetManyIncomingRequestsQuery,
  useGetManyOutgoingRequestsQuery,
  useDeleteOneFactorRequestMutation,
  useUpdateOneFactorRequestMutation,
  useCreateOneFactorRequestMutation,
  useCreateCbamProofMutation,
  useGetPcfProductMatchesQuery,
  useSubmitGhgRequestWithResponseMutation,
  usePrefetch,
} = factorsRequestsApiSlice

export default factorsRequestsApiSlice
