import React, { ReactElement, ReactNode, Suspense, lazy } from 'react'
import { TFunction } from 'react-i18next'

import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import FiltersProvider from '@/contexts/filters'
import LogProvider from '@/contexts/log'

import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'
import SuspenseSpinner from '../SuspenseSpinner'

const Dashboard = lazy(() => import('@/pages/Overview/Dashboard'))
const Organigram = lazy(() => import('@/pages/Overview/Organigram/Organigram'))

const Wrapper = ({
  feature = 'log',
  children,
}: {
  feature?: string
  children: ReactNode
}): ReactElement => (
  <PrivateRoute>
    <FeatureRoute requiredFeature={feature}>
      <Suspense fallback={<SuspenseSpinner />}>{children}</Suspense>
    </FeatureRoute>
  </PrivateRoute>
)

/**
 * All routes in the /share/ namespace
 */
const getOverviewRoutes = (t: TFunction): BreadcrumbsRoute[] =>
  [
    {
      path: routes.overview.digitalTwin,
      element: (
        <Wrapper>
          <LogProvider>
            <Organigram />
          </LogProvider>
        </Wrapper>
      ),
      breadcrumb: t('layout.organigram'),
    },
    {
      path: routes.overview.dashboard,
      element: (
        <Wrapper>
          <LogProvider>
            <FiltersProvider saveQueryString={true}>
              <Dashboard />
            </FiltersProvider>
          </LogProvider>
        </Wrapper>
      ),
    },
  ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[]

export default getOverviewRoutes
